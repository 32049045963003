import { Button, Input, LoadingProgress, Modal } from '@praticabr/ppsa'
import User from 'models/User'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { updateUser } from 'services'
import RenewPassForm from 'shared/RenewPass'
import { store } from 'store'
import { updateUserInfo } from 'store/modules/user/actions'
import LockIcon from './ConfigUserModalLockIcon'
import './styles.scss'

interface Props {
	onUpdateUser: (userInfo: User) => void
	onClose: () => void
}

const ConfigUserModal: React.FC<Props> = ({ onUpdateUser, onClose }) => {
	const [loading, setLoading] = useState<boolean>(false)
	const [editMode, setEditMode] = useState<boolean>(false)
	const [showRenewPassContent, setShowRenewPassContent] =
		useState<boolean>(false)
	const dispatch = useDispatch()

	const user = store.getState().user
	const userName = user.name
	const userSurname = user.surname
	const userPhone = user.cel
	const userEmail = user.email
	const userRegister = user.register
	const userDepartment = user.department

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<User>({
		defaultValues: {
			name: user.name,
			surname: user.surname,
			cel: user.cel,
			email: user.email,
			register: user.register,
			department: user.department
		}
	})

	const handleEditMode = () => {
		setEditMode(!editMode)
	}

	const onSubmitInfo = (userData: any) => {
		setLoading(true)
		updateUser(userData, user.profile)
			.then((response) => {
				const { data } = response
				setLoading(false)
				if (data.name === 'ValidationError') {
					toast.error('Houve um erro ao atualizar os dados do usuário!')
				} else {
					setEditMode(false)
					dispatch(
						updateUserInfo(
							data.userUpdated.name,
							data.userUpdated.surname,
							data.userUpdated.cel,
							data.userUpdated.email,
							data.userUpdated.lastVersion
						)
					)
					onUpdateUser(data)
					toast.success('Dados editados com sucesso!')
				}
			})
			.catch(() => {
				setLoading(false)
				toast.error('Houve um erro ao atualizar os dados do usuário!')
			})
	}

	const userInfoContain = () => {
		return (
			<>
				<Modal.body.main>
					<div className="config-user-main-content">
						<div className="config-user-info">
							<div className="config-user-info-item">
								<span className="user-info-descriptive">Matrícula:</span>
								<span className="user-information">{userRegister}</span>
							</div>
							<div className="config-user-info-item">
								<span className="user-info-descriptive">Área:</span>
								<span className="user-information">{userDepartment}</span>
							</div>
							<div className="config-user-info-item">
								<span className="user-info-descriptive">E-mail:</span>
								<span className="user-information">{userEmail}</span>
							</div>
							<div className="config-user-info-item">
								<span className="user-info-descriptive">Nome:</span>
								<span className="user-information">{userName}</span>
							</div>
							<div className="config-user-info-item">
								<span className="user-info-descriptive">Sobrenome:</span>
								<span className="user-information">{userSurname}</span>
							</div>
							<div className="config-user-info-item">
								<span className="user-info-descriptive">Celular:</span>
								<span className="user-information">{userPhone}</span>
							</div>
						</div>
						<button
							className="change-password-action"
							type="submit"
							onClick={() => setShowRenewPassContent(true)}
						>
							<LockIcon />
							Alterar Senha
						</button>
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<div className="config-user-actions">
						<Button
							title="EDITAR"
							size="lg"
							variant="confirmation-solid-modal"
							onClick={handleEditMode}
						/>
					</div>
				</Modal.body.actions>
			</>
		)
	}

	const editUserInfoContain = () => {
		return (
			<>
				<Modal.body.main>
					<div className="config-user-main-content">
						<div className="config-user-info">
							<div className="config-user-info-item">
								<span className="user-info-descriptive">Matrícula</span>
								<span className="user-information">{userRegister}</span>
							</div>
							<div className="config-user-info-item">
								<span className="user-info-descriptive">Área</span>
								<span className="user-information">{userDepartment}</span>
							</div>
							<div className="config-user-info-item">
								<span className="user-info-descriptive">E-mail</span>
								<span className="user-information">{userEmail}</span>
							</div>
							<div className="config-user-info-item">
								<span className="user-info-descriptive">Nome</span>
								<Input
									id="name"
									variant="light"
									type="text"
									errors={errors.name}
									{...register('name', {
										required: {
											value: true,
											message: '*O campo Nome é obrigatório.'
										}
									})}
								/>
							</div>
							<div className="config-user-info-item">
								<span className="user-info-descriptive">Sobrenome</span>
								<Input
									id="surname"
									variant="light"
									type="text"
									errors={errors.surname}
									{...register('surname', {
										required: {
											value: true,
											message: '*O campo Sobrenome é obrigatório.'
										}
									})}
								/>
							</div>
							<div className="config-user-info-item">
								<span className="user-info-descriptive">Celular</span>
								<Input
									id="cel"
									variant="light"
									type="text"
									errors={errors.cel}
									{...register('cel', {
										required: {
											value: true,
											message: '*O campo Celular é obrigatório.'
										}
									})}
								/>
							</div>
						</div>
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<div className="config-user-actions">
						<Button
							title="SALVAR"
							size="lg"
							variant="confirmation-solid-modal"
							onClick={handleSubmit(onSubmitInfo)}
						/>
						<Button
							title="CANCELAR"
							size="lg"
							variant="cancellation"
							onClick={handleEditMode}
						/>
					</div>
				</Modal.body.actions>
			</>
		)
	}

	return (
		<Modal.root>
			<Modal.header
				title={'DADOS CADASTRAIS'}
				onClose={onClose}
				variant="gradient"
			/>
			<Modal.body>
				{editMode ? editUserInfoContain() : userInfoContain()}
			</Modal.body>
			{showRenewPassContent && (
				<RenewPassForm
					email={user.email}
					handleCancel={() => setShowRenewPassContent(false)}
				/>
			)}
			{loading && <LoadingProgress text="..." />}
		</Modal.root>
	)
}

export default ConfigUserModal
