import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { Button, Modal } from '@praticabr/ppsa'
import { FilterData } from 'models/FilterData'
import { OrderHistoryContextElement } from 'module/orderManagement/context/OrderHistoryContext/OrderHistoryContext'
import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import FilterFields from './FilterFields'
import PendencyField from './PendencyField'
import PeriodFields from './PeriodFields'
import './styles.scss'

interface Params {
	onClose: () => void
	clearFilter?: boolean
}

type FilterParams = {
	initialDate: Date
	finalDate: Date
	orderStatus: string[]
	orderCategory: string[]
	orderBranch: string[]
	pendencyStatus: string
}

type FilterHooks = {
	history_branch_select: string
}

const HistoryFilter: React.FC<Params> = ({ onClose, clearFilter }) => {
	const { onFilterChange, itemFilter } = useContext(OrderHistoryContextElement)
	const [initialDate, setInitialDate] = useState<MaterialUiPickersDate>(
		itemFilter?.initialDate as MaterialUiPickersDate | null
	)
	const [finalDate, setFinalDate] = useState<MaterialUiPickersDate>(
		itemFilter?.finalDate as MaterialUiPickersDate | null
	)
	const [pendencyStatus, setPendencyStatus] = useState<string>()
	const [orderStatus, setOrderStatus] = useState<any[]>([])
	const [orderCategory, setOrderCategory] = useState<string[]>([])
	const [orderBranch, setOrderBranch] = useState<string[]>([])
	const [pendencyCode, setPendencyCode] = useState<string[]>([])
	const [pendencyType, setPendencyType] = useState<string[]>([])
	const [pendencyIssueResolverTeam, setPendencyIssueResolverTeam] = useState<
		string[]
	>([])
	const [pendencyIssueResolver, setPendencyIssueResolver] = useState<string[]>(
		[]
	)
	const [hasError, setHasError] = useState<boolean>(false)

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		resetField,
		clearErrors
	} = useForm<FilterHooks>()

	const onDateSelect = (value: MaterialUiPickersDate, type: string) => {
		type === 'initialDate' ? setInitialDate(value) : setFinalDate(value)
	}

	const onPendencyStatusSelected = (status: string) => {
		setPendencyStatus(status)
	}

	const onSelectOrderStatus = (status: any[]) => {
		setOrderStatus(status)
	}

	const onSelectOrderCategory = (status: string[]) => {
		setOrderCategory(status)
	}

	const onSelectOrderBranch = (status: string[]) => {
		setOrderBranch(status)
	}

	const onSelectPendencyCode = (status: string[]) => {
		setPendencyCode(status)
	}

	const onSelectPendencyType = (status: string[]) => {
		setPendencyType(status)
	}

	const onSelectPendencyIssueResolverTeam = (status: string[]) => {
		setPendencyIssueResolverTeam(status)
	}

	const onSelectPendencyIssueResolver = (status: string[]) => {
		setPendencyIssueResolver(status)
	}

	const applyFilterChanges = () => {
		const newFilter = {
			initialDate,
			finalDate,
			orderStatus,
			orderCategory,
			orderBranch,
			pendencyStatus,
			pendencyCode,
			pendencyType,
			pendencyIssueResolverTeam,
			pendencyIssueResolver
		} as unknown as FilterParams
		onFilterChange(newFilter as FilterData)
		onClose()
	}

	const clearFilters = () => {
		setInitialDate(null)
		setFinalDate(null)
		setOrderCategory([])
		setOrderStatus([])
		setOrderBranch([])
		setPendencyStatus('')
		setPendencyCode([])
		setPendencyType([])
		setPendencyIssueResolverTeam([])
		setPendencyIssueResolver([])
		resetField('history_branch_select')
		clearErrors('history_branch_select')
		setHasError(false)
		onFilterChange({
			initialDate: null,
			finalDate: null,
			orderStatus: [],
			orderCategory: [],
			orderBranch: [],
			pendencyStatus: '',
			pendencyCode: [],
			pendencyType: [],
			pendencyIssueResolverTeam: [],
			pendencyIssueResolver: []
		} as unknown as FilterData)
		onClose()
	}

	const onError = (value: any) => {
		if (!value || Date.parse(value)) {
			setHasError(false)
			return
		}
		setHasError(true)
	}

	const mainContent = () => {
		return (
			<Modal.root>
				<Modal.header
					title="FILTROS"
					onClose={() => onClose()}
					variant="gradient"
				/>
				<Modal.body>
					<Modal.body.main>
						<div className="orders-filter-content-history">
							<div className="clear-filter" onClick={() => clearFilters()}>
								<span>Limpar filtros</span>
							</div>
							<PeriodFields
								{...{ initialDate, finalDate, onDateSelect, onError }}
							/>
							<FilterFields
								{...{
									onSelectOrderStatus,
									orderStatus,
									onSelectOrderCategory,
									orderCategory,
									onSelectOrderBranch,
									orderBranch,
									onSelectPendencyCode,
									pendencyCode,
									onSelectPendencyType,
									pendencyType,
									onSelectPendencyIssueResolverTeam,
									pendencyIssueResolverTeam,
									onSelectPendencyIssueResolver,
									pendencyIssueResolver,
									register,
									errors,
									setValue,
									clearErrors
								}}
							/>
							<PendencyField
								{...{ onPendencyStatusSelected, pendencyStatus }}
							/>
						</div>
						<div className="orders-filter-history-actions">
							<Button
								variant="confirmation-solid-modal"
								title="FILTRAR"
								size="lg"
								disabled={hasError}
								onClick={() => handleSubmit(applyFilterChanges)()}
							/>
							<Button
								variant="cancellation"
								title="CANCELAR"
								size="lg"
								onClick={() => onClose()}
							/>
						</div>
					</Modal.body.main>
				</Modal.body>
			</Modal.root>
		)
	}

	return mainContent()
}

export default HistoryFilter
