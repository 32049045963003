import { DiffEditor } from '@monaco-editor/react'
import { Modal } from '@praticabr/ppsa'
import { LogsContextElement } from 'module/adminSystem/context/LogsContext'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import { handleLogEntity } from 'module/orderManagement/utils/constants'
import React, { useContext, useEffect, useState } from 'react'
import './styles.scss'

interface Props {
	onClose: () => void
}

export default function LogModal({ onClose }: Props) {
	const { viewType } = useContext(DeviceContextElement)
	const { log } = useContext(LogsContextElement)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)
	const [spanMessage, setSpanMessage] = useState<string | null>(null)
	const [compareLog, setCompareLog] = useState<string>('')

	useEffect(() => {
		if (!log) {
			setErrorMessage(null)
			setSpanMessage(null)
			return
		}

		const validLogs = [
			...log.otherLogs.filter(
				(otherLog: any) => otherLog.status && otherLog.id < log.id
			)
		]
		validLogs.length && setCompareLog(validLogs[validLogs.length - 1]?.payload)

		if (log.status) {
			setSpanMessage(
				`${handleLogEntity(log.entity)} alterado com sucesso dia ${new Date(
					log.createdAt
				).toLocaleDateString()}.`
			)
		} else {
			setSpanMessage(
				`Erro ao alterar o ${handleLogEntity(log.entity)} ${new Date(
					log.createdAt
				).toLocaleDateString()}.`
			)
		}

		if (log.integrationError) {
			try {
				const errorMessage = `${JSON.parse(log?.integrationError)
					?.message} \n\n Protheus Error:\n\n ${JSON.parse(
					JSON.stringify(JSON.parse(log?.integrationError)?.error?.message)
				)}`
				setErrorMessage(errorMessage)
			} catch (err) {
				setErrorMessage(log.integrationError)
			}
		}
	}, [log])

	const formatJSON = (value: string) => {
		try {
			const res = JSON?.parse(value)
			return JSON?.stringify(res, null, 2)
		} catch {
			if (value === undefined) {
				return JSON?.stringify({}, null, 2)
			}
			const errorJson = {
				error: `${value}`
			}
			return JSON?.stringify(errorJson, null, 2)
		}
	}

	const handleCloseModal = () => {
		onClose()
	}

	return (
		<Modal.root>
			<Modal.header
				onClose={handleCloseModal}
				title="Detalhes do Log"
				variant="gradient"
			/>
			<Modal.body>
				<Modal.body.main>
					<div className="log-modal">
						<span>{spanMessage}</span>
						<br />
						<span>{`Usuário: ${log.user.name} ${log.user.surname}.`}</span>
						<div className="log-modal-info">
							<DiffEditor
								height={
									viewType === 'mobile'
										? 'calc(86vh - 300px)'
										: errorMessage
										? '300px'
										: '500px'
								}
								language="json"
								options={{
									readOnly: true
								}}
								modified={log?.payload && formatJSON(log.payload)}
								original={compareLog && formatJSON(compareLog)}
							/>
						</div>
						{errorMessage && (
							<div className="log-modal-info">
								<label>Erro de integração</label>
								<textarea defaultValue={errorMessage} readOnly rows={5} />
							</div>
						)}
					</div>
				</Modal.body.main>
			</Modal.body>
		</Modal.root>
	)
}
