import { Button, Input, Modal } from '@praticabr/ppsa'
import React from 'react'
import { useForm } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types'
import './styles.scss'

type TParams = {
	mailParams: {
		body: string
		subject: string
		mailto?: string
	}
	onClose: () => void
}

const EmailSendConfirm = ({ mailParams, onClose }: TParams) => {
	const { body, subject, mailto } = mailParams
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({ defaultValues: { mailto: mailto } })

	const onSubmit = (data: FieldValues) => {
		const { mailto } = data
		window.open(`mailto:${mailto}?subject=${subject}&body=${body}`, '_top')
		onClose()
	}

	return (
		<Modal.root>
			<Modal.header
				variant="gradient"
				title="CONFIRME OS DADOS DO DESTINATÁRIO"
				onClose={() => onClose()}
			/>
			<Modal.body>
				<Modal.body.main>
					<div className="email-contain">
						<div className="email-contain-form">
							<label htmlFor="mailto" className="email-contain-text">
								E-mail:
							</label>
							<Input
								id="mailto"
								type="email"
								variant="light"
								autoComplete="off"
								errors={errors.mailto}
								{...register('mailto', {
									required: {
										value: true,
										message: 'O campo e-mail é obrigatório!'
									}
								})}
							/>
						</div>
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<div className="email-contain-actions">
						<Button
							size="lg"
							title="CONFIRMAR"
							variant="confirmation-solid-modal"
							onClick={handleSubmit(onSubmit)}
						/>
						<Button
							size="lg"
							title="CANCELAR"
							variant="cancellation"
							onClick={() => onClose()}
						/>
					</div>
				</Modal.body.actions>
			</Modal.body>
		</Modal.root>
	)
}

export default EmailSendConfirm
