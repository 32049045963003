import { InputAdornment, TextField } from '@material-ui/core'
import { Divider, EmptyList, LoadingProgress, Modal } from '@praticabr/ppsa'
import $ from 'jquery'
import Technician from 'models/Technician'
import React, { useEffect, useState } from 'react'
import { MdSearch } from 'react-icons/md'
import { toast } from 'react-toastify'
import { getTechListBySpeciality } from 'services'
import { store } from 'store'
import '../styles.css'

export interface ITechnicianForm {
	name: string
	phone: string
	code_prp: string
	email: string
	address: string
	distance: string
	specialty: string
}

interface Props {
	show: boolean
	currentAddress: string
	onClose: (show: boolean) => void
	selectedTechnician?: ITechnicianForm | Technician | null
	onSelectTechnician: (technician: ITechnicianForm) => void

	type?: any
	type_code: any
	state_sigla: any
	buttonReturn: boolean
}

const typeChef = [
	{ value: 'ATENDIMENTO DE URGENCIA' },
	{ value: 'ATENDIMENTO GERAL CONCESSIONARIA' },
	{ value: 'ATENDIMENTO CONCESSIONARIA DEMO' },
	{ value: 'ATENDIMENTO CONCESSIONARIA TESTE' },
	{ value: 'ATENDIMENTO EPP DEMONSTRACAO' },
	{ value: 'DEMONSTRACAO OU TESTE PARA FILIAIS' },
	{ value: 'OUTRAS SOLICITACOES CANAL CHEF' },
	{ value: 'SOLICITACAO DE ATENDIMENTO VIA ASSISTENCIA TECNICA' },
	{ value: 'SOLICITACAO DE ATENDIMENTO VIA ERRO ZERO' },
	{ value: 'SOLICITACAO DE ATENDIMENTO VIA QUALIDADE' },
	{ value: 'SOLICITACAO DE TREINAMENTO BONIFICADO (CHEF+DESPESAS)' },
	{ value: 'SOLICITACAO DE TREINAMENTO BONIFICADO (CHEF)' },
	{ value: 'SOLICITACAO DE TREINAMENTO POLITICA' },
	{ value: 'SOLICITACAO DE TREINAMENTO REVENDA' },
	{ value: 'SOLICITACAO DE TREINAMENTO REVENDA (CHEF+DESPESAS)' },
	{ value: 'SOLICITACAO DE TREINAMENTO REVENDA (CHEF)' },
	{ value: 'VISITA DO CHEF VIA GERENTE/COORDENADOR' },
	{ value: 'VISITA DO CHEF VIA CONCESSIONARIA' }
]

const SelectTech: React.FC<Props> = ({
	show,
	onClose,
	currentAddress,
	selectedTechnician,
	onSelectTechnician,
	type_code,
	state_sigla,
	type
}) => {
	const [techs, setTechs] = useState<any[]>()
	const [showLoading, setShowLoading] = useState<boolean>(false)
	const user = store.getState().user

	useEffect(() => {
		if (
			!!type_code &&
			type_code !== '' &&
			!!state_sigla &&
			state_sigla !== '' &&
			!!currentAddress &&
			currentAddress !== '' &&
			show
		) {
			setShowLoading(true)
			getTechListBySpeciality(
				type_code,
				state_sigla,
				currentAddress,
				user.profile
			)
				.then((response) => {
					if (response?.data?.techList?.length <= 0) {
						toast.error(
							'Nenhum técnico encontrado, por gentileza verifique o endereço do atendimento'
						)
						setShowLoading(false)
					}
					if (typeChef.find((e) => e.value === type)) {
						setTechs(
							response?.data?.techList.filter(
								(e: any) => e.technician_profile === 'Field_chef_autonomous'
							)
						)
						setShowLoading(false)
					} else {
						setTechs(
							response?.data?.techList.filter(
								(e: any) => e.technician_profile !== 'Field_chef_autonomous'
							)
						)
						setShowLoading(false)
					}
					$(`.tech-1`).css('display', 'block')
				})
				.catch(() => {
					toast.error('Erro ao buscar lista de prestadores.')
					setShowLoading(false)
				})
		}
	}, [type_code, state_sigla, currentAddress, type, show])

	const searchTech = (value: string) => {
		if (
			type_code !== undefined &&
			state_sigla !== undefined &&
			currentAddress !== undefined &&
			show
		) {
			const user = store.getState().user
			setShowLoading(true)
			getTechListBySpeciality(
				type_code,
				state_sigla,
				currentAddress,
				user.profile,
				value
			)
				.then((resp) => {
					if (resp.data.techList?.length <= 0) {
						toast.error(
							'Nenhum técnico encontrado, por gentileza verifique o técnico informado'
						)
						setShowLoading(false)
					}
					if (typeChef.find((e) => e?.value === type)) {
						setTechs(
							resp.data.techList.filter(
								(e: any) => e?.technician_profile !== 'Field_tec_autonomous'
							)
						)
						setShowLoading(false)
					} else {
						setTechs(
							resp.data.techList.filter(
								(e: any) => e?.technician_profile !== 'Field_chef_autonomous'
							)
						)
						setShowLoading(false)
					}
				})
				.catch(() => {
					toast.error('Erro ao buscar lista de prestadores.')
					setShowLoading(false)
				})
		}
	}

	let timer: 0 | ReturnType<typeof setTimeout> = 0
	const onSearch = async (value: string) => {
		clearTimeout(timer)
		timer = setTimeout(() => {
			searchTech(value)
			clearTimeout(timer)
		}, 400)
	}

	const TechSelect = (selectedItem: any) => {
		const tech: ITechnicianForm = {
			name: selectedItem?.name,
			code_prp: selectedItem?.code_prp,
			phone: selectedItem?.phone,
			email: selectedItem?.email,
			address: selectedItem?.full_address,
			distance: selectedItem?.distance,
			specialty: selectedItem?.prodType
		}
		onSelectTechnician(tech)
		onClose(!show)
	}

	return (
		<Modal.root>
			<Modal.header
				variant="gradient"
				onClose={() => {
					onClose(!show)
					setTechs([])
				}}
				title="Escolha um técnico"
			/>
			<Modal.body>
				<Modal.body.main>
					<div className="select-tech-header">
						<div className="tech-search">
							<div className="pointer">
								<div className="description">Buscar técnico:</div>
							</div>
							<TextField
								id="codtech"
								className={`codtech`}
								placeholder="Encontre um técnico"
								name="codtech"
								style={{ width: '60%' }}
								onChange={(e) => {
									onSearch(e.target.value)
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position="start">
											<MdSearch />
										</InputAdornment>
									)
								}}
							/>
						</div>
					</div>

					<div className={`codTech`} style={{ display: 'none' }}>
						<div className="search-tech-result">
							<div className="tech">
								<div className="div-block-12">
									<div className="tech-header">
										<div id="name_tech" className="text-block-15">
											{selectedTechnician?.name}
										</div>
									</div>
									<div style={{ display: 'flex' }}>
										<div id="id_tech" className="tech-id">
											{`ID: ${selectedTechnician?.code_prp}`}
										</div>
									</div>
									<div className="text-block-16">
										<strong id="phone_tech" className="bold-text">
											{`Telefone: ${selectedTechnician?.phone}`}
										</strong>
									</div>
									<div className="text-block-16">
										<strong id="email_tech" className="bold-text-2">
											{`E-mail: ${selectedTechnician?.email}`}
										</strong>
									</div>
									<div className="text-block-16">
										<strong id="address_tech" className="bold-text-3">
											{`Endereço: ${selectedTechnician?.address}`}
										</strong>
									</div>
									<div className="div-block-14">
										<div
											className="text-block-19"
											onClick={() => onClose(!show)}
										>
											Escolher
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<Divider title="Técnicos recomendados:" />

					<div className="request-technician1">
						<div className="address-block">
							{techs?.length ? (
								techs?.map((item, index) => {
									return (
										<div
											className="tech"
											onClick={() => TechSelect(item)}
											key={index}
										>
											<div className="div-block-12">
												<div className="tech-header">
													<div id="name_tech" className="text-block-15">
														{item?.name}
													</div>
													{Number(item?.distance) * 2 > 60 ? (
														<div
															id="distance_tech"
															style={{
																color: 'red',
																fontSize: '12px',
																border: 'solid 1px',
																borderRadius: '3px',
																padding: '5px 10px',
																marginTop: '2px'
															}}
														>
															<p>{`${item?.distance} km excedente,`}</p>
															<p>{`verificar autorização!`}</p>
														</div>
													) : (
														<div id="distance_tech">{item?.distance}km</div>
													)}
												</div>
												<div id="id_tech" className="tech-id">
													{`ID: ${item?.code_prp}`}
												</div>
												<div className="text-block-16">
													<strong id="phone_tech" className="bold-text">
														{`Telefone: ${item?.phone ? item?.phone : ''} ${
															item?.phone1 && ` | ${item?.phone1}`
														} ${item?.cel && ` | ${item?.cel}`}`}
													</strong>
												</div>
												<div className="text-block-16">
													<strong id="email_tech" className="bold-text-2">
														{`E-mail: ${item?.email}`}
													</strong>
												</div>
												<div className="text-block-16">
													<strong id="address_tech" className="bold-text-3">
														{`Endereço: ${item?.full_address}`}
													</strong>
												</div>
												<div className="text-block-16">
													<strong id="specialty_tech" className="bold-text-1">
														{`Especializado em: ${item?.prodType}`}
													</strong>
												</div>
												<div className="div-block-14">
													<div className="text-block-19">Escolher</div>
												</div>
											</div>
										</div>
									)
								})
							) : (
								<div className="empty-list-component">
									<EmptyList text="Nenhum técnico encontrado" />
								</div>
							)}
						</div>
						{showLoading && <LoadingProgress />}
					</div>
				</Modal.body.main>
			</Modal.body>
		</Modal.root>
	)
}
export default SelectTech
