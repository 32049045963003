import { Checkbox, FormControlLabel } from '@material-ui/core'
import { Button, Divider, LoadingProgress, Modal } from '@praticabr/ppsa'
import zonesList from 'assets/js/fs.json'
import {
	NotificationProfile,
	NotificationTypes
} from 'models/notificationProfile'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { setNotificationsProfile } from 'services'
import { store } from 'store'
import { osTypes, osTypesChefs } from 'utils/os-types.constants'
import OrderTypesList from './OrderTypesList'
import ZonesList from './ZonesList'
import './styles.scss'

interface Props {
	userProfile: NotificationProfile
	types: NotificationTypes[]
	onClose: () => void
}

const FOLLOW_UP = 'FOLLOW_UP'

const NotificationsConfig: React.FC<Props> = ({
	userProfile,
	types,
	onClose
}) => {
	const [loading, setLoading] = useState<boolean>(false)
	const [showZones, setShowZones] = useState<boolean>(false)
	const [editZones, setEditZones] = useState<boolean>(false)
	const [editTypes, setEditTypes] = useState<boolean>(false)
	const [selectedTypes, setSelectedTypes] = useState<string[]>(
		userProfile.types
	)
	const [selectedZones, setSelectedZones] = useState<string[]>(
		userProfile.zonesFollow
	)
	const [selectedOsTypes, setSelectedOsTypes] = useState<string[]>(
		userProfile.osTypesFollow
	)

	const userId = store.getState().user.profile
	const zonesInUse = userProfile.zonesInUse || []
	const notificationsZones = zonesList
	const notificationsTypes = types
	const notificationsOrdersTypes = osTypes.concat(osTypesChefs)

	useEffect(() => {
		const followUpIsSelected = selectedTypes.find((i) => i === FOLLOW_UP)
		followUpIsSelected ? setShowZones(true) : setShowZones(false)
	}, [selectedTypes])

	const handleSubmit = () => {
		const request = {
			selectedTypes,
			selectedZones,
			selectedOsTypes,
			userId
		}

		setLoading(true)
		setNotificationsProfile(request)
			.then(() => {
				setLoading(false)
				toast.success('Configurações aplicadas com sucesso!')
				onClose()
			})
			.catch((err) => {
				console.log(err)
				setLoading(false)
				toast.error('Houve um erro ao aplicar configurações!')
			})
	}

	const handleSelectionTypes = (itemSelected: string) => {
		const currentIndex = selectedTypes.findIndex((i) => i === itemSelected)
		const newSelectedList = [...selectedTypes]

		if (currentIndex === -1) {
			newSelectedList.push(itemSelected)
		} else {
			newSelectedList.splice(currentIndex, 1)
		}

		setSelectedTypes(newSelectedList)
	}

	const typesContent = () => {
		return (
			<div>
				<Divider title="Tipos de notificações" />
				<div className="notifications-types-list">
					{notificationsTypesOptions()}
				</div>
			</div>
		)
	}

	const notificationsTypesOptions = () => {
		return (
			<>
				{notificationsTypes.map((type, index) => {
					return (
						<FormControlLabel
							key={index}
							className="checkbox-item"
							control={
								<Checkbox
									color="secondary"
									onClick={() => {
										handleSelectionTypes(type.type)
									}}
									checked={selectedTypes.includes(type.type)}
									disabled={isTypeDisabled(type.type)}
									value={type}
								/>
							}
							label={type.label}
						/>
					)
				})}
			</>
		)
	}

	const isTypeDisabled = (item: string) => {
		const inUseCount = zonesInUse?.length
		const zonesCount = notificationsZones?.length

		return inUseCount === zonesCount && item === FOLLOW_UP
	}

	const zonesContent = () => {
		return (
			showZones && (
				<div>
					<Divider title="Áreas de Follow-up" />
					<div className="notifications-items-list">{notifyZonesContent()}</div>
					<div className="notifications-items-area-footer">
						<p onClick={handleZonesModalToggle}>
							Selecionar Áreas de Follow-up
						</p>
					</div>
				</div>
			)
		)
	}

	const notifyZonesContent = () => {
		const itemsToShow = notificationsZones.filter((zone) =>
			selectedZones.includes(zone.initials)
		)

		if (itemsToShow?.length) {
			return (
				<>
					{itemsToShow.map((zone, index) => {
						return selectedItemContent(zone.label, index)
					})}
				</>
			)
		} else {
			return <span>Nenhum estado de acompanhamento selecionado.</span>
		}
	}

	const ordersTypesContent = () => {
		return (
			showZones && (
				<div>
					<Divider title="Tipos de Atendimentos" />
					<div className="notifications-items-list">
						{notifyOrdersTypesContent()}
					</div>
					<div className="notifications-items-area-footer">
						<p onClick={handleOsTypesModalToggle}>
							Selecionar Tipos de Atendimentos
						</p>
					</div>
				</div>
			)
		)
	}

	const notifyOrdersTypesContent = () => {
		const itemsToShow = notificationsOrdersTypes.filter((osType) =>
			selectedOsTypes.includes(osType.value)
		)

		if (itemsToShow?.length) {
			return (
				<>
					{itemsToShow.map((osType, index) => {
						return selectedItemContent(osType.label, index)
					})}
				</>
			)
		} else {
			return <span>Nenhum tipo de atendimento selecionado.</span>
		}
	}

	const selectedItemContent = (value: string, index: number) => {
		return (
			<div key={index}>
				<span className="notifications-selected-item">{value}</span>
			</div>
		)
	}

	const handleZonesModalToggle = () => {
		setEditZones(!editZones)
	}

	const handleSubmitZonesModal = (zones: string[]) => {
		setSelectedZones(zones)
	}

	const handleSubmitOsTypesModal = (osTypes: string[]) => {
		setSelectedOsTypes(osTypes)
	}

	const handleOsTypesModalToggle = () => {
		setEditTypes(!editTypes)
	}

	const notificationsConfigMainContain = () => {
		return (
			<div className="notifications-config-main-contain">
				{typesContent()}
				{zonesContent()}
				{ordersTypesContent()}
			</div>
		)
	}

	const notificationsConfigFooterContain = () => {
		return (
			<div className="notifications-config-footer-contain">
				<Button
					style={{ width: '126px' }}
					size="lg"
					title="Confirmar"
					variant="confirmation-solid-modal"
					disabled={
						showZones && (!selectedZones?.length || !selectedOsTypes?.length)
					}
					onClick={handleSubmit}
				/>
			</div>
		)
	}

	return (
		<>
			<Modal.root>
				<Modal.header
					title="Configurar Notificações"
					onClose={onClose}
					variant="gradient"
				/>
				<Modal.body>
					<Modal.body.main>{notificationsConfigMainContain()}</Modal.body.main>
					<Modal.body.actions>
						{notificationsConfigFooterContain()}
					</Modal.body.actions>
				</Modal.body>
			</Modal.root>
			{editTypes && (
				<OrderTypesList
					userSelectedOsTypes={selectedOsTypes}
					onSubmit={handleSubmitOsTypesModal}
					onClose={handleOsTypesModalToggle}
				/>
			)}
			{editZones && (
				<ZonesList
					userSelectedZones={selectedZones}
					onSubmit={handleSubmitZonesModal}
					onClose={handleZonesModalToggle}
				/>
			)}
			{loading && <LoadingProgress text="Carregando..." />}
		</>
	)
}

export default NotificationsConfig
