import { Button, Modal, Select } from '@praticabr/ppsa'
import React from 'react'
import { onUpdateFile } from 'services'
import './styles.scss'

type AttachmentCategoryProps = {
	onClose: () => void
	file: any
}

const fileAttachmentCategories = [
	{ value: '', label: 'Geral' },
	{ value: 'attachmentsFinance', label: 'Financeiro' },
	{ value: 'attachmentsShipping', label: 'Expedição' },
	{ value: 'attachmentsOrder', label: 'ADM de Pedidos' }
]

export const AttachmentCategoryModal = ({
	onClose,
	file
}: AttachmentCategoryProps) => {
	const [selectedCategory, setSelectedCategory] = React.useState(
		file?.attachmentCategory || file?.attachment_category || ''
	)

	const onSubmit = () => {
		onUpdateFile({ id: file.id, attachment_category: selectedCategory }).then(
			() => {
				onClose()
			}
		)
	}

	return (
		<Modal.root>
			<Modal.header
				title="Editar Categoria do Anexo"
				{...{ onClose }}
				variant="gradient"
			/>
			<Modal.body>
				<Modal.body.main>
					<div className="attachment-category-modal">
						<div className="header">
							<p>
								Caso a categoria seja alterada para uma que você não tenha
								permissão, você e outros usuários sem a permissão também não
								poderão visualizar o anexo.
							</p>
						</div>
						<div className="container">
							<div className="description">
								<p>{file?.attachmentName || file?.name}</p>
							</div>
							<div className="category">
								<Select
									options={fileAttachmentCategories}
									selectedOption={[selectedCategory || '']}
									onSelectOptions={(option) => setSelectedCategory(option[0])}
								/>
							</div>
						</div>
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<div className="action">
						<Button
							title="Salvar"
							variant="confirmation-solid-modal"
							size="lg"
							onClick={onSubmit}
						/>
						<Button
							title="Cancelar"
							variant="cancellation"
							size="lg"
							onClick={onClose}
						/>
					</div>
				</Modal.body.actions>
			</Modal.body>
		</Modal.root>
	)
}
