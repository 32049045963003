import { ClientPrp } from 'models/ClientPrp'
import Sa1010Overview, {
	FinancePosition,
	GroupFinancePosition
} from 'models/Sa1010Overview'
import { Client } from 'module/orderManagement/domain/models/client'
import { updateClientSchema } from 'module/orderManagement/schemas/update-client-schema'

export interface ClientDetailsContextType {
	availableClientChange: Client[] | undefined
	clientPrp: ClientPrp | null
	clientPrpIsLoading: boolean
	onCreateClient: (data: updateClientSchema) => void
	onSelectClient: (client: { a1Cod: string; a1Loja: string }) => void
	onUpdateClient: (
		client: { a1Cod: string; a1Loja: string },
		data: updateClientSchema,
		callback?: () => void
	) => void
	onUpdateSameBillingAddress: (option: boolean) => void
	sameBillingAddresses: boolean
	sa1010: Sa1010Overview | null
	sa1010IsLoading: boolean
	financePositionLoading: boolean
	groupFinancePositionLoading: boolean
	financePosition: FinancePosition | null
	groupFinancePosition: GroupFinancePosition | null
	searchClient: (value: string) => void
	isLoading: boolean
}

export const ClientDetailsContextValue = {
	availableClientChange: [] as Client[],
	clientPrp: {} as ClientPrp,
	clientPrpIsLoading: {} as boolean,
	onCreateClient: () => {},
	onSelectClient: () => {},
	onUpdateClient: () => {},
	onUpdateSameBillingAddress: () => {},
	sameBillingAddresses: {} as boolean,
	sa1010: {} as Sa1010Overview,
	financePosition: null,
	groupFinancePosition: null,
	sa1010IsLoading: false,
	financePositionLoading: false,
	groupFinancePositionLoading: false,
	searchClient: () => {},
	isLoading: false
}
