import { Button, Modal, Search } from '@praticabr/ppsa'
import Filter from 'assets/image/Filter.svg'
import financialBlockIcon from 'assets/image/financial-block.svg'
import financialNotBlockIcon from 'assets/image/financial-not-block.svg'
import { ClientContextElement } from 'module/orderManagement/context/ClientContext/ClientContext'
import React, { useContext, useState } from 'react'
import { history } from 'routes/history'
import SearchIcon from 'shared/components/SvgIcons/SearchIcon'
import ClientsFilter from '../ClientsFilter'
import './styles.scss'

const ClientsActions = () => {
	const { onSearchClient } = useContext(ClientContextElement)
	const [clientsFilterShowModal, setClientsFilterShowModal] =
		useState<boolean>(false)

	return (
		<div className="clients-actions">
			<div className="clients-search-and-filter">
				<Search.root>
					<Search.icon>
						<SearchIcon height="100%" width="100%" />
					</Search.icon>
					<Search.input
						normalize
						placeholder="Procurar Cliente"
						onChangeValues={(e) => onSearchClient(e)}
					/>
				</Search.root>
				<img
					src={Filter}
					alt="filter icon"
					onClick={() => setClientsFilterShowModal(true)}
				/>
			</div>
			<div className="clients-legends">
				<span>
					<img src={financialBlockIcon} alt="financial block icon" /> Com
					bloqueio financeiro
				</span>
				<span>
					<img src={financialNotBlockIcon} alt="financial not block icon" /> Sem
					bloqueio financeiro
				</span>
			</div>
			<div className="clients-bt-new-order">
				<Button
					size="lg"
					title="NOVO CLIENTE"
					variant="confirmation-solid"
					onClick={() => history.push('/clients/new-client')}
				/>
			</div>
			{clientsFilterShowModal && (
				<Modal.root>
					<Modal.header
						title="FILTROS"
						onClose={() => setClientsFilterShowModal(false)}
						variant="gradient"
					/>
					<Modal.body>
						<Modal.body.main>
							<ClientsFilter onClose={() => setClientsFilterShowModal(false)} />
						</Modal.body.main>
					</Modal.body>
				</Modal.root>
			)}
		</div>
	)
}
export default ClientsActions
