import {
	Button,
	GenericTable,
	Input,
	LoadingList,
	Modal
} from '@praticabr/ppsa'
import Sa1010Overview from 'models/Sa1010Overview'
import { handleAccessoriesType } from 'module/orderManagement/utils/constants'
import React, { useEffect, useState } from 'react'
import {
	LuCalendarCheck2,
	LuCalendarClock,
	LuCalendarX,
	LuEye
} from 'react-icons/lu'
import { toast } from 'react-toastify'
import { sendBillets } from 'services'
import './styles.scss'

export function BilletsModal({
	sa1010,
	onClose
}: {
	sa1010: Sa1010Overview
	onClose: () => void
}) {
	const [selectedItens, setSelectedEquipments] = useState<any[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [emailToSend, setEmailToSend] = useState<string>('')

	useEffect(() => {
		setEmailToSend(
			sa1010?.a1Email
				? `${sa1010?.a1Email}`
				: sa1010?.a1Emailcp
				? `${sa1010?.a1Emailcp}`
				: ''
		)
	}, [sa1010])

	function handleCheckAll(filter?: string) {
		setSelectedEquipments(
			sa1010?.se1010?.map((se1) => ({
				...se1,
				checkbox: true,
				id: se1?.rECNO,
				dueDate: handleTransformDateProtheus(se1?.e1Vencto),
				order: `${se1?.e1Filial} - ${se1?.e1Pedido}`,
				balance: se1?.e1Saldo?.toLocaleString('pt-BR', {
					style: 'currency',
					currency: 'BRL'
				}),
				invoice: `${se1?.e1Num} - ${se1?.e1Prefixo}`
			}))
		)
	}

	function handleBranchTypes(branch?: string) {
		if (!branch) return '-'
		const branchType = BranchTypes?.filter(
			(FreightType) => FreightType?.value === branch
		)
		return branchType[0]?.label || '-'
	}

	const BranchTypes = [
		{ value: '01', label: 'MG' },
		{ value: '02', label: 'SP' },
		{ value: '06', label: 'PE' }
	]

	const legends = {
		overdueInvoice: {
			key: 'overdueInvoice',
			status: <LuCalendarX color="#C21618" size={18} />,
			label: 'Vencido'
		},
		today: {
			key: 'today',
			status: <LuCalendarClock color="#5DAEFF" size={18} />,
			label: 'Vencimento Hoje'
		},
		future: {
			key: 'future',
			status: <LuCalendarCheck2 color="#048243" size={18} />,
			label: 'Vencimento Futuro'
		}
	}

	const statusChange = (e1Vencto: string) => {
		const currentDate = new Date()
		const currentYear = currentDate?.getFullYear()
		const currentMonth = currentDate?.getMonth() + 1
		const currentDay = currentDate?.getDate()

		const year = parseInt(e1Vencto?.substring(0, 4))
		const mes = parseInt(e1Vencto?.substring(4, 6))
		const day = parseInt(e1Vencto?.substring(6, 8))

		if (
			year > currentYear ||
			(year === currentYear && mes > currentMonth) ||
			(year === currentYear && mes === currentMonth && day > currentDay)
		) {
			return 'future'
		} else if (
			year === currentYear &&
			mes === currentMonth &&
			day === currentDay
		) {
			return 'today'
		} else {
			return 'overdueInvoice'
		}
	}

	const handleTransformDateProtheus = (date?: string) => {
		if (!date) return '-'
		return new Date(
			Number(date?.substring(0, 4)),
			Number(date?.substring(4, 6)) - 1,
			Number(date?.substring(6, 8))
		)?.toLocaleDateString('pt-BR')
	}

	async function onRowActionClick(data: any) {
		if (
			!data ||
			!data?.e1Numbor ||
			!data?.e1Prefixo ||
			!data?.e1Num ||
			!data?.e1Tipo
		)
			return toast.error('Boleto não encontrado')

		window.open(
			`http://boleto.cloud.praticabr.com:8788/${data?.e1Numbor?.trim()}-${data?.e1Prefixo?.trim()}${data?.e1Num?.trim()}${data?.e1Parcela?.trim()}-${data?.e1Tipo?.trim()}.pdf`,
			'_blank'
		)
	}

	const handleSelectItems = (item: any) => {
		delete item?.action
		const currentIndex = selectedItens?.findIndex(
			(equipment) =>
				equipment?.id === item?.id && equipment?.code === item?.code
		)

		const newChecked = [...selectedItens]

		if (currentIndex === -1) {
			newChecked?.push(item)
		} else {
			newChecked?.splice(currentIndex, 1)
		}
		setSelectedEquipments(newChecked)
	}

	async function onSendEmail() {
		const recnos = selectedItens?.map((item) => item?.rECNO)

		const data = {
			e1Cliente: sa1010?.a1Cod,
			e1Loja: sa1010?.a1Loja,
			email: emailToSend,
			recnos
		}

		setIsLoading(true)
		sendBillets(data)
			.then(() => toast.success('Boletos enviados com sucesso!'))
			.catch(() =>
				toast.error('Erro ao enviar boletos. Por favor, tente novamente.')
			)
			.finally(() => {
				setIsLoading(false)
				onClose()
			})
	}

	return (
		<Modal.root>
			<Modal.header title="Boletos" onClose={onClose} variant="gradient" />
			<Modal.body>
				<Modal.body.main>
					<div className="billets-modal">
						<div className="billets-position-section">
							<div className="billets-position-section-row">
								<div className="billets-position-section-contain-information">
									<header>Email do cliente</header>
									<span>
										{sa1010?.a1Email || sa1010?.a1Emailcp
											? `${
													sa1010?.a1Email
														? `${sa1010?.a1Email}`
														: sa1010?.a1Emailcp
														? `${sa1010?.a1Emailcp}`
														: ''
											  }`
											: '-'}
									</span>
								</div>
								<div className="billets-position-section-contain-information">
									<header>Permite e-mail?</header>
									<span>{handleAccessoriesType(sa1010?.a1Blemail)}</span>
								</div>
							</div>
							<div className="billets-position-section-row">
								<div className="billets-position-section-contain-input">
									<Input
										name="email"
										variant="light-normal"
										defaultValue={emailToSend}
										onChange={(e) => setEmailToSend(e?.target?.value)}
									/>
								</div>
								<div className="billets-position-section-contain-button">
									<Button
										size="md"
										icon="e-mail"
										title="E-MAIL"
										variant="outline"
										onClick={onSendEmail}
										disabled={
											isLoading || selectedItens?.length === 0 || !emailToSend
										}
									/>
								</div>
							</div>
						</div>
						<div className="legend-header">
							{Object.values(legends)?.map((legend) => {
								return (
									<div className="legend" key={legend?.key}>
										{legend?.status}
										<span>{legend?.label}</span>
									</div>
								)
							})}
						</div>
						<div className="billets-position-section-table">
							{isLoading ? (
								<LoadingList text="Enviando e-mail" />
							) : (
								<GenericTable
									onRowClicked={handleSelectItems}
									onActionRadio={(e: any) => {
										if (e?.target?.checked) {
											handleCheckAll()
										} else {
											setSelectedEquipments([])
										}
									}}
									data={[
										...(sa1010?.se1010?.map((se1) => ({
											...se1,
											checkbox: true,
											id: se1?.rECNO,
											dueDate: handleTransformDateProtheus(se1?.e1Vencto),
											order: `${handleBranchTypes(
												se1?.e1Filial
											)} - ${se1?.e1Pedido}`,
											balance: se1?.e1Saldo?.toLocaleString('pt-BR', {
												style: 'currency',
												currency: 'BRL'
											}),
											invoice: `${se1?.e1Num} - ${se1?.e1Prefixo}`,
											actionTable: (
												<LuEye
													size={20}
													color="var(--text-primary-pure)"
													onClick={() => onRowActionClick(se1)}
												/>
											),
											status: legends[statusChange(se1?.e1Vencto)]?.status
										})) ?? [])
									]}
									columns={[
										{
											width: '5%',
											checkbox: true,
											key: 'checkbox',
											selected: selectedItens,
											header: ''
										},
										{
											key: 'status',
											header: '',
											width: '38px'
										},
										{
											width: '15%',
											key: 'dueDate',
											header: 'Vencimento'
										},
										{
											width: 'calc(20% - 38px)',
											key: 'order',
											header: 'Pedido'
										},
										{
											width: '23%',
											key: 'invoice',
											header: 'Nota Fiscal'
										},
										{
											width: '15%',
											key: 'e1Parcela',
											header: 'Parcela'
										},
										{
											width: '17%',
											key: 'balance',
											header: 'Saldo'
										},
										{
											width: '5%',
											key: 'actionTable',
											header: ''
										}
									]}
								/>
							)}
						</div>
					</div>
				</Modal.body.main>
			</Modal.body>
		</Modal.root>
	)
}
