import { Section } from '@praticabr/ppsa'
import React, { ReactNode } from 'react'
import './styles.scss'

type RootProps = {
	children: ReactNode
}

export const Root = ({ children }: RootProps) => {
	return (
		<Section.root>
			<Section.title>Códigos promocionais aplicados</Section.title>
			<Section.container>{children}</Section.container>
		</Section.root>
	)
}
