import { ClientPrp } from 'models/ClientPrp'
import Sa1010Overview from 'models/Sa1010Overview'
import { Client } from 'module/orderManagement/domain/models/client'
import { updateClientSchema } from 'module/orderManagement/schemas/update-client-schema'
import { getClientByValue } from 'module/orderManagement/services'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
	createClientRest,
	getClientPrpOverview,
	getSa1010FinancePosition,
	getSa1010Overview,
	updateClientRest
} from 'services'
import { OrderHistoryContextElement } from '../OrderHistoryContext/OrderHistoryContext'
import {
	ClientDetailsContextType,
	ClientDetailsContextValue
} from './ClientDetailsContextType'

export const ClientDetailsContextElement =
	createContext<ClientDetailsContextType>(ClientDetailsContextValue)

export const ClientProvider = (props: any) => {
	const { onUpdateList } = useContext(OrderHistoryContextElement)
	const [availableClientChange, setAvailableClientChange] = useState<Client[]>()
	const [clientPrpIsLoading, setClientPrpIsLoading] = useState<boolean>(false)
	const [financePosition, setFinancePosition] = useState<any | null>(null)
	const [groupFinancePosition, setGroupFinancePosition] = useState<any | null>(
		null
	)
	const [financePositionLoading, setFinancePositionLoading] =
		useState<boolean>(false)
	const [groupFinancePositionLoading, setGroupFinancePositionLoading] =
		useState<boolean>(false)
	const [sa1010IsLoading, setSa1010IsLoading] = useState<boolean>(false)
	const [clientPrp, setClientPrp] = useState<ClientPrp | null>(null)
	const [sa1010, setSa1010] = useState<Sa1010Overview | null>(null)
	const [sameBillingAddresses, setSameBillingAddresses] =
		useState<boolean>(true)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const { pathname } = location

	useEffect(() => {
		if (pathname.split('/')[1] === 'client') {
			const a1Cod = pathname.split('/')[2]
			const a1Loja = pathname.split('/')[3]
			if (a1Cod !== sa1010?.a1Cod || a1Loja !== sa1010?.a1Loja) {
				setSa1010(null)
				setClientPrp(null)
				setFinancePosition(null)
				requestSa1010({ a1Cod, a1Loja })
			}
		}
	}, [pathname])

	useEffect(() => {
		if (sa1010) {
			setSameBillingAddresses(sa1010.a1End === sa1010.a1Endcob)
			requestClientPrp({ a1Cod: sa1010.a1Cod, a1Loja: sa1010.a1Loja })
			onUpdateList(sa1010.a1Cod)
		}
	}, [sa1010])

	const requestSa1010 = (selectedClient: { a1Cod: string; a1Loja: string }) => {
		if (selectedClient) {
			setSa1010IsLoading(true)
			getSa1010Overview(selectedClient)
				.then((res) => {
					setSa1010(res.data)
					const { a1Grpven } = res.data
					setFinancePositionLoading(true)
					getSa1010FinancePosition({ ...selectedClient })
						.then((res) => setFinancePosition(res.data))
						.catch((err) => console.log(err))
						.finally(() => setFinancePositionLoading(false))
					if (a1Grpven) {
						setGroupFinancePositionLoading(true)
						getSa1010FinancePosition({ ...selectedClient, a1Grpven })
							.then((res) => setGroupFinancePosition(res.data))
							.catch((err) => console.log(err))
							.finally(() => setGroupFinancePositionLoading(false))
					}
				})
				.catch(() => setSa1010(null))
				.finally(() => setSa1010IsLoading(false))
		}
	}

	const requestClientPrp = (selectedClient: {
		a1Cod: string
		a1Loja: string
	}) => {
		if (selectedClient) {
			setClientPrpIsLoading(true)
			getClientPrpOverview(selectedClient)
				.then((res) => {
					setClientPrp(res.data)
				})
				.catch(() => {
					setClientPrp(null)
					setFinancePosition(null)
				})
				.finally(() => setClientPrpIsLoading(false))
		}
	}

	const onCreateClient = (data: updateClientSchema) => {
		setSa1010IsLoading(true)
		createClientRest(data)
			.then((res) => {
				toast.success('Cliente cadastrado com sucesso!')
				setTimeout(() => {
					window.location.replace(
						`/client/${res.data.a1Cod}/${res.data.a1Loja}`
					)
				}, 500)
			})
			.catch((error) => {
				const toastMessage = error.response.data?.error?.message
				toast.error(toastMessage || 'Erro ao cadastrar cliente')
				setSa1010IsLoading(false)
			})
	}

	const onSelectClient = (client: { a1Cod: string; a1Loja: string }) => {
		setSa1010(null)
		setClientPrp(null)
		setFinancePosition(null)
		requestSa1010(client)
	}

	const onUpdateClient = (
		client: { a1Cod: string; a1Loja: string },
		data: updateClientSchema,
		callback?: () => void
	) => {
		setSa1010IsLoading(true)
		updateClientRest(client, data)
			.then(() => {
				onSelectClient(client)
				toast.success('Cliente atualizado com sucesso!')
				callback && callback()
			})
			.catch((error) => {
				const toastMessage = error.response.data?.error?.message
				toast.error(toastMessage || 'Erro ao atualizar cliente')
				setSa1010IsLoading(false)
			})
	}

	const onUpdateSameBillingAddress = (option: boolean) => {
		setSameBillingAddresses(option)
	}

	const searchClient = (value: string) => {
		setIsLoading(true)
		getClientByValue(value?.toUpperCase())
			.then((res) => {
				const { client } = res.data
				setAvailableClientChange(client)
			})
			.catch((err) => console.log(err))
			.finally(() => setIsLoading(false))
	}

	const values = {
		availableClientChange,
		clientPrp,
		clientPrpIsLoading,
		onCreateClient,
		onSelectClient,
		onUpdateClient,
		sameBillingAddresses,
		sa1010,
		sa1010IsLoading,
		financePositionLoading,
		groupFinancePositionLoading,
		financePosition,
		groupFinancePosition,
		searchClient,
		onUpdateSameBillingAddress,
		isLoading
	}

	return (
		<ClientDetailsContextElement.Provider value={values}>
			{props.children}
		</ClientDetailsContextElement.Provider>
	)
}
