import { Button, LoadingProgress, Modal } from '@praticabr/ppsa'
import Client from 'models/Client'
import Equipment from 'models/Equipment'
import Equipments from 'module/technicalServices/components/Equipments'
import React, { useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { registerClosingOs, uploadFile } from 'services'
import UploadComponent from 'shared/UploadComponent'
import { store } from 'store'
import {
	Characters,
	Footer,
	Form,
	Header,
	Info,
	Input,
	InputArea,
	LabelName,
	Message,
	RequestInfo
} from './styles'
import './styles.scss'

interface Props {
	equipment?: Equipment
	client?: Client
	serviceOrderId: number
	handleChanges: (type: any, changes: any, worked: boolean) => void
	handleCancel: () => void
}

interface Info {
	contact: string
	occupation: string
	message: string
	url: string
}

const ClosingOs: React.FC<Props> = ({
	client,
	equipment,
	serviceOrderId,
	handleChanges,
	handleCancel
}) => {
	const [files, setFiles] = useState<any[]>([])
	const [text, setText] = useState<string>('')
	const [msg, setMsg] = useState<number>(500)
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm()
	const user = store.getState().user
	const url_so = window.location.href
	const [load, setLoad] = useState(false)
	const [uploadedFiles, setUploadedFiles] = useState<any[]>([])

	useEffect(() => {
		return () => {
			uploadedFiles.forEach((file) => URL.revokeObjectURL(file.preview))
		}
	}, [uploadedFiles])

	const uploaded = (files: any[]) => {
		setUploadedFiles(files.filter((file) => file.error !== true))
	}

	const handleCancelUpload = () => {
		uploadedFiles.map((file, index) => {
			if (index >= 0) {
				uploadFile.delete(`upload/${file.id}`)
			}
		})
	}

	const onSubmit = (info: FieldValues) => {
		setLoad(true)
		const uploadFiles = uploadedFiles.filter((file) => file.error !== true)
		const technicalAssistanceFile = {
			contact: info.contact,
			occupation: info.occupation,
			message: text,
			url: 'Visualize o atendimento na timeline: ' + url_so,
			files: files
		}
		const techAssist = {
			serviceOrderId,
			technicalAssistanceFile,
			user,
			uploadFiles
		}

		registerClosingOs(techAssist)
			.then(() => {
				setLoad(false)
				onClose()
				toast.success('Ordem de serviço encerrada com sucesso.')
				setTimeout(() => {
					window.location.reload()
				}, 600)
			})
			.catch(() => {
				setLoad(false)
				toast.error('Erro ao encerrar ordem de serviço.')
			})
	}

	const countMsg = (e: any) => {
		if (text?.length < 500) {
			setText(e.target.value?.substring(0, 500))
			if (
				e.nativeEvent.inputType == 'insertText' ||
				e.nativeEvent.inputType == 'insertFromPaste'
			) {
				setMsg(500 - (text?.length + 1))
			} else {
				setMsg(500 - (text?.length - 1))
			}
		} else {
			if (
				e.nativeEvent.inputType == 'insertText' ||
				e.nativeEvent.inputType == 'insertFromPaste'
			) {
				setMsg(0)
			} else {
				setText(e.target.value?.substring(0, 500))
				setMsg(500 - (text?.length + 1))
			}
		}
	}

	const onClose = () => {
		$('input:text, textarea').val('')
		$(`.closingOs`).toggle()
		$('.actionOpt').css('display', 'none')
		$(`#fileList`).html('')
		handleCancel()
	}

	return (
		<Modal.root>
			<Modal.header
				variant="gradient"
				onClose={() => {
					handleCancelUpload()
					onClose()
				}}
				title="Encerrar atendimento"
			/>
			<Modal.body>
				<Modal.body.main>
					<div className="service-order-close-os">
						<div className="service-order-close-os-main-contain">
							<Header>
								<Equipments client={client} equipment={equipment} />
							</Header>

							<form>
								<RequestInfo>
									<Form>
										<Info>
											<div style={{ width: '50%', paddingRight: '10px' }}>
												<LabelName>Contato</LabelName>
												<Input
													type="text"
													{...register('contact', { required: true })}
												/>
												<small>
													{errors.contact && (
														<span style={{ color: 'red', display: 'flex' }}>
															O campo contato é obrigatório
														</span>
													)}
												</small>
											</div>
											<div style={{ width: '50%' }}>
												<LabelName>Cargo</LabelName>
												<Input
													type="text"
													{...register('occupation', { required: true })}
												/>
												<small>
													{errors.occupation && (
														<span style={{ color: 'red', display: 'flex' }}>
															O campo cargo é obrigatório
														</span>
													)}
												</small>
											</div>
										</Info>
										<Message>
											<LabelName>Mensagem</LabelName>
											<InputArea
												{...register('message', { required: true })}
												onChange={(e) => {
													countMsg(e)
												}}
												value={text}
											/>
											<small>
												{errors.message && (
													<span style={{ color: 'red', display: 'flex' }}>
														O campo mensagem é obrigatório
													</span>
												)}
											</small>
										</Message>
										<Characters>{msg} caracteres restantes</Characters>
										<UploadComponent
											onUploaded={uploaded}
											keyUpload={`${serviceOrderId}`}
										/>
									</Form>
								</RequestInfo>
							</form>
						</div>
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<Footer>
						<Button
							style={{ width: '120px' }}
							variant="confirmation-solid-modal"
							size="lg"
							title="Encerrar"
							onClick={handleSubmit(onSubmit)}
						/>
						<Button
							style={{ width: '120px' }}
							variant="cancellation"
							size="lg"
							title="Cancelar"
							onClick={() => {
								handleCancelUpload()
								onClose()
							}}
						/>
					</Footer>
				</Modal.body.actions>
			</Modal.body>
			{load && <LoadingProgress text="Carregando..." />}
		</Modal.root>
	)
}

export default ClosingOs
