import { updateUserToken } from 'services'
import { store } from 'store'

export default async function handleCleanCacheCookies() {
	const userId = store.getState().user?.profile
	const isLogged = store.getState().auth?.signed

	// Limpar caches
	if ('caches' in window) {
		const cacheNames = await caches.keys()
		await Promise.all(cacheNames.map((cache) => caches.delete(cache)))
	}

	// Limpar Local Storage e Session Storage
	localStorage.clear()
	sessionStorage.clear()

	// Remover cookies corretamente
	document.cookie.split(';').forEach((cookie) => {
		const [name] = cookie.split('=')
		document.cookie = `${name.trim()}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${
			window.location.hostname
		}`
	})

	// Reset Redux Store
	store.dispatch({ type: 'RESET_STORE' })

	// Invalidar token do usuário caso esteja logado
	if (isLogged) {
		await updateUserToken(null, userId)
	}

	// Desativar Service Workers (se existirem)
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.getRegistrations().then((registrations) => {
			registrations.forEach((registration) => registration.unregister())
		})
	}

	// Recarregar a página para garantir que tudo seja atualizado
	window.location.reload()
}
