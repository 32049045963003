import { GenericTable } from '@praticabr/ppsa'
import React from 'react'
import './styles.scss'

type PromotionalCodesProps = {
	items: {
		item: string
		code: string
		description: string
		promotionalCode: string
	}[]
}

export const PromotionalCodes = ({ items }: PromotionalCodesProps) => {
	return (
		<div className="promotional-codes">
			<GenericTable
				data={items?.map((item, index) => ({
					id: index,
					item: item?.item,
					code: item?.code,
					product: item?.description,
					promotionalCode: item?.promotionalCode
				}))}
				columns={[
					{
						header: 'Item',
						key: 'item',
						width: '10%'
					},
					{
						header: 'Código',
						key: 'code',
						width: '10%'
					},
					{
						header: 'Descrição',
						key: 'product',
						width: '60%'
					},
					{
						header: 'Código promocional',
						key: 'promotionalCode',
						width: '20%'
					}
				]}
			/>
		</div>
	)
}
