import { Modal } from '@praticabr/ppsa'
import React, { ReactNode } from 'react'
import './styles.scss'

interface Props {
	onClose: () => void
	itemSelect?: any
	children: ReactNode
}

export const ItensDetailsListAccessoriesRoot = ({
	onClose,
	children
}: Props) => {
	return (
		<Modal.root>
			<Modal.header title="ACESSÓRIOS" onClose={onClose} variant="gradient" />
			<Modal.body>
				<Modal.body.main>{children}</Modal.body.main>
			</Modal.body>
		</Modal.root>
	)
}
