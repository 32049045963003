import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { Button, Modal } from '@praticabr/ppsa'
import { FilterData } from 'models/FilterData'
import { ClientContextElement } from 'module/orderManagement/context/ClientContext/ClientContext'
import { FilterSave } from 'module/orderManagement/models/FilterSave'
import React, { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setClientsFiltersClear } from 'store/modules/orderManagement/actions'
import BlockField from './BlockField'
import FilterFields from './FilterFields'
import MyFilters from './MyfiltersClient'
import PeriodFields from './PeriodFields'
import './styles.scss'

interface Params {
	onClose: () => void
}

const ClientsFilter: React.FC<Params> = ({ onClose }) => {
	const { itemFilter, onFilterChange, filtersParams } =
		useContext(ClientContextElement)
	const [financialStatus, setFinancialStatus] = useState(
		filtersParams?.financialStatus?.length
			? filtersParams?.financialStatus
			: itemFilter?.financialStatus
	)
	const [salesPersonCode, setSalesPersonCode] = useState(
		filtersParams?.salesPersonCode?.length
			? filtersParams?.salesPersonCode
			: itemFilter?.salesPersonCode
	)
	const [federatedState, setFederatedState] = useState(
		filtersParams?.federatedState?.length
			? filtersParams?.federatedState
			: itemFilter?.federatedState
	)
	const [myFiltersShowModal, setMyFiltersShowModal] = useState<boolean>(false)
	const [saveOnNewFilter, setSaveOnNewFilter] = useState<any[]>([])
	const [saveFilterEditing, setSaveFilterEditing] = useState<FilterSave>()
	const [buttonShow, setButtonShow] = useState<boolean>(false)
	const [initialDate, setInitialDate] = useState<MaterialUiPickersDate>(
		itemFilter?.initialDate as MaterialUiPickersDate | null
	)
	const [finalDate, setFinalDate] = useState<MaterialUiPickersDate>(
		itemFilter?.finalDate as MaterialUiPickersDate | null
	)
	const [hasError, setHasError] = useState<boolean>(false)
	const dispatch = useDispatch()

	const onDateSelect = (value: MaterialUiPickersDate, type: string) => {
		type === 'initialDate' ? setInitialDate(value) : setFinalDate(value)
	}

	const onSelectSalesPerson = (status: string[]) => {
		setSalesPersonCode(status)
	}

	const onSelectFederalState = (status: string[]) => {
		setFederatedState(status)
	}

	const applyFilterChanges = () => {
		const newFilter = {
			initialDate,
			financialStatus,
			federatedState,
			finalDate,
			salesPersonCode
		} as FilterData
		onFilterChange(newFilter)
		onClose()
	}

	const clearFilters = () => {
		dispatch(setClientsFiltersClear())
		setSalesPersonCode([])
		setFederatedState([])
		setFinancialStatus([])
		setInitialDate(null)
		setFinalDate(null)
		setHasError(false)
		const clearFilter = {
			financialStatus: [],
			federatedState: [],
			salesPersonCode: []
		} as unknown as FilterData
		onFilterChange(clearFilter)
		onClose()
	}

	const onError = (value: any) => {
		if (!value || Date.parse(value)) {
			setHasError(false)
			return
		}
		setHasError(true)
	}

	const filterEditing = (item: any) => {
		setFinancialStatus(item.financialStatus)
		setFederatedState(item.federatedState)
		setSalesPersonCode(item.salesPersonCode)
		setSaveFilterEditing(item)
	}

	const mainContent = () => {
		return salesPersonCode && federatedState ? (
			<Modal.root>
				<Modal.header
					onClose={() => onClose()}
					title="FILTROS"
					variant="gradient"
				/>
				<Modal.body>
					<div className="clients-filter-content">
						<div className="clients-filter-header-actions">
							<span
								onClick={() => {
									setMyFiltersShowModal(true)
									setSaveOnNewFilter([])
									setButtonShow(false)
									setSaveFilterEditing(undefined)
								}}
							>
								Meus filtros
							</span>
							<span onClick={() => clearFilters()}>Limpar filtros</span>
						</div>
						<PeriodFields
							{...{ initialDate, finalDate, onDateSelect, onError }}
						/>
						<FilterFields
							{...{
								onSelectSalesPerson,
								salesPersonCode,
								onSelectFederalState,
								federatedState
							}}
						/>
						<BlockField {...{ setFinancialStatus, financialStatus }} />
						<div className="clients-filter-actions">
							{!!buttonShow && (
								<Button
									variant="standard"
									title="SALVAR FILTRO"
									size="lg"
									onClick={() => {
										setMyFiltersShowModal(true)
										setSaveOnNewFilter([
											{
												id: null,
												salesPersonCode,
												federatedState,
												financialStatus
											}
										])
									}}
								/>
							)}
							{saveFilterEditing?.id && (
								<Button
									variant="standard"
									title="SALVAR"
									size="lg"
									onClick={() => {
										setSaveOnNewFilter([
											{
												id: saveFilterEditing?.id,
												name: saveFilterEditing?.name,
												salesPersonCode,
												federatedState,
												financialStatus
											}
										])
										setSaveFilterEditing(undefined)
										setMyFiltersShowModal(true)
									}}
								/>
							)}
							<Button
								variant="confirmation-solid-modal"
								title="FILTRAR"
								size="lg"
								disabled={hasError}
								onClick={applyFilterChanges}
							/>
							<Button
								variant="cancellation"
								title="CANCELAR"
								size="lg"
								onClick={onClose}
							/>
						</div>
						{myFiltersShowModal && (
							<MyFilters
								myFiltersCloseModal={(close) => {
									setMyFiltersShowModal(close)
									setSaveOnNewFilter([])
								}}
								saveOnNewFilter={saveOnNewFilter}
								newFilter={setButtonShow}
								onClose={() => {
									onClose()
									setMyFiltersShowModal(false)
								}}
								onEditingFilter={(e) => {
									filterEditing(e)
								}}
							/>
						)}
					</div>
				</Modal.body>
			</Modal.root>
		) : (
			<></>
		)
	}

	return mainContent()
}

export default ClientsFilter
