import { Button, Select } from '@praticabr/ppsa'
import { RequisitionError } from 'models/RequisitionError'
import { OrderDetailsContextElement } from 'module/orderManagement/context/OrderDetailsContext/OrderDetailsContext'
import { Order } from 'module/orderManagement/domain/models/order'
import { IPendencyCreateCloudRequest } from 'module/orderManagement/models/cloudTotvsRequisitionModels/IPendencyCloudRequisition'
import { PendencyTypes } from 'module/orderManagement/utils/constants'
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { store } from 'store'
import { removeAccents } from 'utils/Pipe'
import './styles.scss'

type NewPendencyProps = {
	order: Order
	onCancel: (showNewPendencyModal: boolean) => void
}

const NewPendency: React.FC<NewPendencyProps> = ({ order, onCancel }) => {
	const { onCreateZZF010 } = useContext(OrderDetailsContextElement)
	const [categoryPendencyList, setCategoryPendencyList] = useState<
		{ value: string; label: string }[]
	>([])
	const [currentPendencyList, setCurrentPendencyList] = useState<string[]>([])
	const [selectedCategory, setSelectedCategory] = useState<string>('1')
	const { availablePendencyList } = store.getState().baseLists
	const { name, surname } = store.getState().user
	const [isLoading, setIsLoading] = useState<boolean>(false)

	useEffect(() => {
		switch (selectedCategory) {
			case '1':
				setSelectedCategory('1')
				setCategoryPendencyList(availablePendencyList.commercial_pending)
				return
			case '2':
				setSelectedCategory('2')
				setCategoryPendencyList(availablePendencyList.financial_pending)
				return
			case '3':
				setSelectedCategory('3')
				setCategoryPendencyList(availablePendencyList.administrative_pending)
				return
			case '4':
				setSelectedCategory('4')
				setCategoryPendencyList(availablePendencyList.shipment_pending)
				return
			default:
				return
		}
	}, [availablePendencyList, selectedCategory])

	const handleCreatePendency = () => {
		setIsLoading(true)
		const onCompletedCreate = (hasError?: RequisitionError) => {
			if (hasError) {
				toast.error(`Erro ao criar pendencia!`)
				setIsLoading(false)
			} else {
				setIsLoading(false)
				onCancel(false)
				toast.success(`Pendência criada com sucesso!`)
			}
		}

		if (currentPendencyList?.length === 0) {
			toast.error('Selecione ao menos uma pendência!')
		} else {
			const createPendencyPayload: IPendencyCreateCloudRequest = {
				user: removeAccents(`${name} ${surname}`),
				order_code: order?.code,
				order_branch: order?.branch,
				pendency_code: currentPendencyList
			}
			onCreateZZF010(createPendencyPayload, onCompletedCreate)
		}
	}

	return (
		<>
			<div className="new-pendency">
				<div className="new-pendency-category">
					<span className="new-pendency-category-name">Categoria</span>
					<Select
						id="commercial_pending"
						options={PendencyTypes}
						onSelectOptions={(e) => setSelectedCategory(e[0])}
						selectedOption={[selectedCategory]}
					/>
				</div>
				<div className="new-pendency-category">
					<span className="new-pendency-category-name">Pendência</span>
					<Select
						id="financial_pending"
						options={categoryPendencyList}
						selectedOption={currentPendencyList}
						checkbox={true}
						onSelectOptions={setCurrentPendencyList}
					/>
				</div>
			</div>
			<div className="new-pendency-actions">
				<Button
					size="lg"
					title="ADICIONAR"
					variant="confirmation-solid-modal"
					onClick={handleCreatePendency}
					disabled={isLoading}
				/>
				<Button
					size="lg"
					title="CANCELAR"
					variant="cancellation"
					onClick={() => onCancel(false)}
				/>
			</div>
		</>
	)
}

export default NewPendency
