import { Modal } from '@praticabr/ppsa'
import { Transfer, TransferClient, TransferUpdate } from 'models/Transfer'
import AutoCompleteInput from 'module/technicalServices/components/Shared/Forms/AutoCompleteInput'
import CustomButtom from 'module/technicalServices/components/Shared/Forms/CustomButton'
import LoadingProgress from 'module/technicalServices/components/Shared/Loadings/LoadingProgress'
import { TransfersDetailsContextElement } from 'module/technicalServices/context/Transfers/TransfersDetailsContext/TransfersDetailsContext'
import React, { useContext, useEffect, useState } from 'react'
import { MdErrorOutline } from 'react-icons/md'
import { toast } from 'react-toastify'
import { TransferUpdateTypes } from 'utils/Constants'
import { clearNumericField, normalizeCGC, phoneMask } from 'utils/Pipe'
import TransferEditContact from '../TransferEditContact'

interface Props {
	transfer: Transfer
	onUpdate: (transferUpdate: TransferUpdate) => void
}

const TransferEditClient: React.FC<Props> = ({ transfer, onUpdate }) => {
	const { transferSelected, transferClients, isLoading, onRequestClients } =
		useContext(TransfersDetailsContextElement)

	const [clientData, setClientData] = useState<TransferClient>()

	const [showEditContactModal, setShowEditContactModal] =
		useState<boolean>(false)

	useEffect(() => {
		setClientData(getTransferClient())
	}, [])

	const getTransferClient = () => {
		const clientPrp = transfer.transferPayloadParsed.prpInfo.newClient
		const transferInfo = transfer.transferPayloadParsed.transferInfo

		const newTransferClient = {
			id: clientPrp ? clientPrp.id : '01',
			name: clientPrp ? clientPrp.nome_fantasia : '',
			cpfCnpj: clearNumericField(transferInfo.cpfCnpj),
			email: transferInfo.email,
			phone: transferInfo.phone,
			contact: transferInfo.name
		} as TransferClient

		return newTransferClient
	}

	const onEditContactClick = () => {
		setShowEditContactModal(true)
	}

	const onEditContactClose = () => {
		setShowEditContactModal(false)
	}

	const onSelectContact = (newClientData: TransferClient) => {
		if (clientData) {
			const client = { ...clientData }
			client.contact = newClientData.name
			client.email = newClientData.email
			client.phone = newClientData.phone
			setClientData(client)
			onEditContactClose()
		}
	}

	const onSelectClient = (client: any) => {
		const newTransferClient = {
			id: client.id,
			name: client.name,
			contact: client.contact,
			cpfCnpj: client.cpfCnpj,
			email: client.email,
			phone: client.phone,
			isOnDataBase: true
		} as TransferClient

		setClientData(newTransferClient)
	}

	const onSubmit = () => {
		if (clientData) {
			const transferUpdate = {
				id: transfer?.id,
				cpfCnpj: clearNumericField(clientData.cpfCnpj),
				name: clientData.contact,
				email: clientData.email,
				phone: clientData.phone,
				type: TransferUpdateTypes.CLIENT_UPDATE
			} as TransferUpdate
			onUpdate(transferUpdate)
		} else {
			toast.error('Nenhum cliente foi selecionado!')
		}
	}

	const onSearchClients = (value: string) => {
		setClientData(undefined)
		onRequestClients(value)
	}

	const formContent = () => {
		const clientPrp = transferSelected?.transferPayloadParsed.prpInfo.newClient
		const defaultValue = clientPrp ? clientPrp : getTransferClient()

		return (
			<AutoCompleteInput
				listItems={transferClients}
				selectedItem={defaultValue}
				keyField="id"
				filterField="name"
				placeHolder="Pesquisar cliente"
				loadingText="Buscando clientes..."
				onChangeValue={onSearchClients}
				onSelectValue={onSelectClient}
			/>
		)
	}

	const clientNameContent = () => {
		return clientData?.name ? (
			<span title={clientData?.name || ''}>
				<b>Nome Fantasia:</b>
				{clientData?.name}
			</span>
		) : (
			<></>
		)
	}

	const clientContent = () => {
		return (
			<div className="edit-client-info">
				{clientNameContent()}
				<span>
					<b>CPF/CNPJ:</b>
					{normalizeCGC(clientData?.cpfCnpj)}
				</span>
				<span>
					<b>Contato:</b>
					{clientData?.contact}
				</span>
				<span title={clientData?.email || ''}>
					<b>E-mail:</b>
					{clientData?.email}
				</span>
				<span>
					<b>Telefone:</b>
					{phoneMask(clientData?.phone)}
				</span>
			</div>
		)
	}

	const clientValidatorWarning = () => {
		return (
			<div className="edit-client-info-warning">
				<MdErrorOutline />
				<h5>Cliente é obrigatório</h5>
			</div>
		)
	}

	const handleClientContent = () => {
		if (clientData && !isLoading) {
			return clientContent()
		} else if (isLoading) {
			return loadingContent()
		} else {
			return clientValidatorWarning()
		}
	}

	const editContactModalContent = () => {
		const title = 'Editar Contato'
		const show = showEditContactModal
		const client = clientData
		return (
			show && (
				<Modal.root>
					<Modal.header
						{...{ title, onClose: onEditContactClose }}
						variant="gradient"
					/>
					<Modal.body>
						<Modal.body.main>
							<TransferEditContact {...{ client, onSelectContact }} />
						</Modal.body.main>
					</Modal.body>
				</Modal.root>
			)
		)
	}

	const loadingContent = () => {
		return <LoadingProgress text="Buscando clientes..." show={isLoading} />
	}

	const handleDisableSubmitButtom = () => {
		const invalidClientData =
			!clientData?.cpfCnpj ||
			!clientData?.contact ||
			!clientData?.email ||
			!clientData?.phone
		return !clientData || invalidClientData
	}

	const mainContent = () => {
		return (
			<>
				<div className="transfer-edit-client-content">
					{formContent()}
					{handleClientContent()}
					<div className="edit-client-footer">
						<CustomButtom
							type="button"
							label="Editar Contato"
							context="secondary"
							onClick={onEditContactClick}
							disabled={!clientData}
						/>
						<CustomButtom
							type="button"
							label="Alterar"
							context="primary"
							onClick={onSubmit}
							disabled={handleDisableSubmitButtom()}
						/>
					</div>
				</div>
				{editContactModalContent()}
			</>
		)
	}

	return mainContent()
}

export default TransferEditClient
