import {
	Input,
	LoadingProgress,
	Modal,
	MoreOptions,
	Radio
} from '@praticabr/ppsa'
import IconAdd from 'assets/image/Add.svg'
import Filter from 'models/Filter'
import { FilterData } from 'models/FilterData'
import { MoreOptionsItem } from 'models/MoreOptionsItem'
import { ClientContextElement } from 'module/orderManagement/context/ClientContext/ClientContext'
import { FilterSave } from 'module/orderManagement/models/FilterSave'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { getFilters, removeFilter, saveFilter } from 'services'
import { store } from 'store'
import {
	setClientsFilters,
	setClientsFiltersClear
} from 'store/modules/orderManagement/actions'
import './styles.scss'

interface Props {
	myFiltersCloseModal: (value: boolean) => void
	newFilter: (value: boolean) => void
	onClose: (value: boolean) => void
	saveOnNewFilter: Filter[]
	onEditingFilter: (value: any) => void
}

const MyFilters: React.FC<Props> = ({
	myFiltersCloseModal,
	saveOnNewFilter,
	newFilter,
	onClose,
	onEditingFilter
}) => {
	const { onFilterChange, onUpdateList } = useContext(ClientContextElement)
	const [nameFilter, setNameFilter] = useState<string>()
	const [filters, setFilters] = useState<Filter[]>([])
	const [filterSelected, setFilterSelected] = useState<Filter>(
		store.getState().orderManagement.ordersMenu.filterSelected
	)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const user = store.getState().user
	const { salespersonList } = store.getState().baseLists
	const dispatch = useDispatch()

	useEffect(() => {
		if (saveOnNewFilter[0]?.id) {
			onSubmit({ name: saveOnNewFilter[0].name, id: saveOnNewFilter[0].id })
			requestFilters()
			onUpdateList
		} else if (
			!!saveOnNewFilter[0]?.federatedState?.length ||
			!!saveOnNewFilter[0]?.salesPersonCode?.length ||
			!!saveOnNewFilter[0]?.financialStatus?.length
		) {
			setFilters((prev) => [...prev, ...saveOnNewFilter])
		} else {
			requestFilters()
		}
	}, [])

	const optionsContent = (item: any) => {
		const optionEdit: MoreOptionsItem = {
			label: 'Editar',
			onClick: () => {
				onEditingFilter(item)
				myFiltersCloseModal(false)
			}
		}
		const optionDelete: MoreOptionsItem = {
			label: 'Excluir',
			onClick: async () => {
				await removeFilter(item.id)
				if (filterSelected?.id === item.id) {
					dispatch(setClientsFiltersClear())
					const clearFilter = {
						federatedState: [],
						salesPersonCode: [],
						financialStatus: []
					} as unknown as FilterData
					onFilterChange(clearFilter)
				}
				requestFilters()
			}
		}

		const moreOptions = [optionEdit, optionDelete]
		return (
			<div className="options">
				<MoreOptions
					id="service-order-list-item-options"
					options={moreOptions}
				/>
			</div>
		)
	}

	const requestFilters = async () => {
		setIsLoading(true)
		await getFilters(user.profile, 'order_management_clients')
			.then((resp) => {
				const filters = resp.data
				filters.map((item) => {
					item.federatedState = JSON.parse(item.filter_state)
					item.salesPersonCode = JSON.parse(item.filter_salesperson)
					item.financialStatus = JSON.parse(item.filter_financial_blocked)
				})
				setFilters(filters)
			})
			.catch((error) => console.log(error))
			.finally(() => setIsLoading(false))
	}

	const onSubmit = async (data: any) => {
		const filterId = data.id ? data.id : undefined
		const filter_state = JSON.stringify(saveOnNewFilter[0].federatedState)
		const filter_salesperson = JSON.stringify(
			saveOnNewFilter[0].salesPersonCode
		)
		const filter_financial_blocked = JSON.stringify(
			saveOnNewFilter[0].financialStatus
		)
		const filter: FilterSave = {
			id: filterId,
			name: data.name,
			module: 'order_management_clients',
			user_id: user.profile,
			filter_state: filter_state,
			filter_salesperson: filter_salesperson,
			filter_financial_blocked: filter_financial_blocked
		}
		await saveFilter(filter)
			.then(() => toast.success('Filtro salvo com sucesso!'))
			.catch(() => toast.error('Erro ao salvar o filtro.'))
	}

	const convertingLabel = (item: any, list: any[]) => {
		const itens: any[] = []
		list.find((e) => {
			if (item.includes(e.value)) {
				itens.push(e.label)
			}
		})
		if (!item?.length) return '-'

		return itens.join(', ')
	}

	const handleSelect = (item: Filter) => {
		const applyFilter = {
			id: item.id,
			financialStatus: item.financialStatus,
			federatedState: item.federatedState,
			salesPersonCode: item.salesPersonCode
		} as FilterData
		setFilterSelected(item)
		onFilterChange(applyFilter)
		dispatch(setClientsFilters(applyFilter))
	}

	const myFilterHeader = () => {
		return (
			<div className="my-filter-head">
				<div className="my-filter-head-th" />
				<div className="my-filter-head-name">
					<span>Nome</span>
				</div>
				<div className="my-filter-head-salesperson">
					<span>Representante</span>
				</div>
				<div className="my-filter-head-state">
					<span>Estados</span>
				</div>
				<div className="my-filter-head-financial">
					<span>Bloqueio Financeiro</span>
				</div>
				<div className="my-filter-head-options"></div>
				<div className="my-filter-head-th" />
			</div>
		)
	}

	const myFilterBody = () => {
		return (
			<>
				{filters?.map((item, index) => (
					<div className="my-filter-body-list" key={index}>
						<div className="my-filter-body-td" />
						<div className="my-filter-body-name">
							{!item.id ? (
								<Input
									name="nameFilter"
									onChange={(e) => setNameFilter(e.target.value)}
									id="name-filter"
									placeholder="Nomeie seu filtro"
									variant="light-normal-check"
									onValueInput={async () => {
										await onSubmit({ name: nameFilter })
										requestFilters()
										newFilter(false)
									}}
								/>
							) : (
								<Radio
									name={`${item.name}`}
									type={'radio'}
									variant="blue"
									id={`${item.id}`}
									checked={item.id === filterSelected?.id}
									onChange={() => {
										handleSelect(item)
										onClose(false)
									}}
								/>
							)}
						</div>
						<div className="my-filter-body-salesperson">
							<span
								title={`${convertingLabel(
									item.salesPersonCode,
									salespersonList
								)}`}
							>
								{convertingLabel(item.salesPersonCode, salespersonList)}
							</span>
						</div>
						<div className="my-filter-body-state">
							<span title={`${item.federatedState.join(', ')}`}>
								{item.federatedState.join(', ')}
							</span>
						</div>
						<div className="my-filter-body-financial">
							<span>
								{item.financialStatus[0] === 'S'
									? 'Sim'
									: item.financialStatus[0] === 'N'
									? 'Não'
									: '-'}
							</span>
						</div>
						<div className="my-filter-body-options">{optionsContent(item)}</div>
						<div className="my-filter-body-td" />
					</div>
				))}
			</>
		)
	}

	return (
		<Modal.root>
			<Modal.header
				title="MEUS FILTROS"
				onClose={() => {
					myFiltersCloseModal(false)
					requestFilters()
				}}
				variant="gradient"
			/>
			<Modal.body>
				<Modal.body.main>
					<div className="my-filter-container-clients">
						<div className="my-filter-content">
							<div className="my-filter-table">
								<div className="my-filter-header-content">
									{myFilterHeader()}
								</div>
								<div className="my-filter-body-content">{myFilterBody()}</div>
							</div>
						</div>
						<div className="new-filter">
							<a
								onClick={() => {
									newFilter(true)
									myFiltersCloseModal(false)
								}}
							>
								<img src={IconAdd} />
								<span>Criar Novo Filtro</span>
							</a>
						</div>
					</div>
				</Modal.body.main>
			</Modal.body>
			{isLoading && <LoadingProgress />}
		</Modal.root>
	)
}

export default MyFilters
