import { Input, Modal, MoreOptions, Radio } from '@praticabr/ppsa'
import IconAdd from 'assets/image/Add.svg'
import Filter from 'models/Filter'
import { FilterData } from 'models/FilterData'
import { MoreOptionsItem } from 'models/MoreOptionsItem'
import { OrderContextElement } from 'module/orderManagement/context/OrderContext/OrderContext'
import { FilterSave } from 'module/orderManagement/models/FilterSave'
import {
	OrderBranchTypes,
	OrderCategoryTypes,
	OrderStatusTypes,
	PendencyTypes,
	ResponsibleSolutionTypes
} from 'module/orderManagement/utils/constants'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { getFilters, removeFilter, saveFilter } from 'services'
import { store } from 'store'
import {
	setOrdersFilters,
	setOrdersFiltersClear
} from 'store/modules/orderManagement/actions'
import { listChangeData } from '../../../Web/OrdersFilter/FilterFields'
import './styles.scss'

interface Props {
	myFiltersCloseModal: (value: boolean) => void
	newFilter: (value: boolean) => void
	onClose: (value: boolean) => void
	saveOnNewFilter: Filter[]
	onEditingFilter: (value: any) => void
}

const MyFilters: React.FC<Props> = ({
	myFiltersCloseModal,
	saveOnNewFilter,
	newFilter,
	onClose,
	onEditingFilter
}) => {
	const { onFilterChange } = useContext(OrderContextElement)
	const [nameFilter, setNameFilter] = useState<string>()
	const [filters, setFilters] = useState<Filter[]>([])
	const [filterSelected, setFilterSelected] = useState<Filter>(
		store.getState().orderManagement.ordersMenu.filterSelected
	)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const user = store.getState().user
	const { salespersonList, availablePendencyList } = store.getState().baseLists
	const dispatch = useDispatch()

	useEffect(() => {
		if (saveOnNewFilter[0]?.id) {
			onSubmit({ name: saveOnNewFilter[0].name, id: saveOnNewFilter[0].id })
			requestFilters()
		} else if (
			!!saveOnNewFilter[0]?.federatedState?.length ||
			!!saveOnNewFilter[0]?.orderStatus?.length ||
			!!saveOnNewFilter[0]?.salesPersonCode?.length ||
			!!saveOnNewFilter[0]?.orderCategory?.length ||
			!!saveOnNewFilter[0]?.pendencyStatus?.length ||
			!!saveOnNewFilter[0]?.orderBranch?.length ||
			!!saveOnNewFilter[0]?.pendencyCode?.length ||
			!!saveOnNewFilter[0]?.pendencyType?.length ||
			!!saveOnNewFilter[0]?.pendencyIssueResolverTeam?.length ||
			!!saveOnNewFilter[0]?.pendencyIssueResolver?.length ||
			!!saveOnNewFilter[0]?.pendencyDate?.length
		) {
			const updateListFilter = saveOnNewFilter.concat(filters)
			setFilters(updateListFilter)
		} else {
			requestFilters()
		}
	}, [])

	const optionsContent = (item: any) => {
		const optionEdit: MoreOptionsItem = {
			label: 'Editar',
			onClick: () => {
				onEditingFilter(item)
				myFiltersCloseModal(false)
			}
		}
		const optionDelete: MoreOptionsItem = {
			label: 'Excluir',
			onClick: async () => {
				await removeFilter(item.id)
				if (filterSelected?.id === item.id) {
					dispatch(setOrdersFiltersClear())
					const clearFilter = {
						pendencyStatus: undefined,
						orderCategory: [],
						orderStatus: [],
						orderBranch: [],
						federatedState: [],
						salesPersonCode: [],
						pendencyCode: [],
						pendencyType: [],
						pendencyIssueResolverTeam: [],
						pendencyIssueResolver: [],
						pendencyDate: []
					} as unknown as FilterData
					onFilterChange(clearFilter)
				}
				requestFilters()
			}
		}

		const moreOptions = [optionEdit, optionDelete]
		return (
			<div className="options">
				<MoreOptions
					id="service-order-list-item-options"
					options={moreOptions}
				/>
			</div>
		)
	}

	const requestFilters = async () => {
		setIsLoading(true)
		await getFilters(user.profile, 'order_management_orders')
			.then((resp) => {
				const filters = resp.data
				filters.map((item) => {
					item.federatedState = JSON.parse(item.filter_state)
					item.orderStatus = JSON.parse(item.filter_status)
					item.salesPersonCode = JSON.parse(item.filter_salesperson)
					item.orderCategory = JSON.parse(item.filter_category)
					item.pendencyStatus = JSON.parse(item.filter_pendency)
					item.orderBranch = JSON.parse(item.filter_branch)
					item.pendencyCode = JSON.parse(item.pendency_code)
					item.pendencyType = JSON.parse(item.pendency_type)
					item.pendencyIssueResolverTeam = JSON.parse(
						item.pendency_resolver_team
					)
					item.pendencyIssueResolver = JSON.parse(item.pendency_resolver)
					item.pendencyDate = JSON.parse(item.pendency_date)
				})
				setFilters(filters)
			})
			.catch((error) => console.log(error))
			.finally(() => setIsLoading(false))
	}

	const onSubmit = async (data: any) => {
		const filterId = data.id ? data.id : undefined
		const filter_state = JSON.stringify(saveOnNewFilter[0].federatedState)
		const filter_status = JSON.stringify(saveOnNewFilter[0].orderStatus)
		const filter_salesperson = JSON.stringify(
			saveOnNewFilter[0].salesPersonCode
		)
		const filter_category = JSON.stringify(saveOnNewFilter[0].orderCategory)
		const filter_pendency = JSON.stringify(saveOnNewFilter[0].pendencyStatus)
		const filter_branch = JSON.stringify(saveOnNewFilter[0].orderBranch)
		const pendency_code = JSON.stringify(saveOnNewFilter[0].pendencyCode)
		const pendency_type = JSON.stringify(saveOnNewFilter[0].pendencyType)
		const pendency_resolver_team = JSON.stringify(
			saveOnNewFilter[0].pendencyIssueResolverTeam
		)
		const pendency_resolver = JSON.stringify(
			saveOnNewFilter[0].pendencyIssueResolver
		)
		const pendency_date = JSON.stringify(saveOnNewFilter[0].pendencyDate)
		const filter: FilterSave = {
			id: filterId,
			name: data.name,
			module: 'order_management_orders',
			filter_state: filter_state,
			filter_status: filter_status,
			user_id: user.profile,
			filter_salesperson: filter_salesperson,
			filter_category: filter_category,
			filter_pendency: filter_pendency,
			filter_branch: filter_branch,
			pendency_code: pendency_code,
			pendency_type: pendency_type,
			pendency_resolver_team: pendency_resolver_team,
			pendency_resolver: pendency_resolver,
			pendency_date: pendency_date
		}
		await saveFilter(filter)
			.then(() => toast.success('Filtro salvo com sucesso!'))
			.catch(() => toast.error('Erro ao salvar o filtro.'))
	}

	const convertingLabel = (item: any, list: any[]) => {
		const itens: any[] = []
		list.find((e) => {
			if (item?.includes(e.value)) {
				itens.push(e.label)
			}
		})
		if (!item?.length) return '-'

		return itens.join(', ')
	}

	const handleSelect = (item: Filter) => {
		const applyFilter = {
			id: item.id,
			pendencyStatus: item.pendencyStatus,
			orderCategory: item.orderCategory,
			orderStatus: item.orderStatus,
			orderBranch: item.orderBranch,
			federatedState: item.federatedState,
			salesPersonCode: item.salesPersonCode,
			pendencyCode: item.pendencyCode,
			pendencyType: item.pendencyType,
			pendencyIssueResolverTeam: item.pendencyIssueResolverTeam,
			pendencyIssueResolver: item.pendencyIssueResolver,
			pendencyDate: item.pendencyDate
		} as FilterData
		setFilterSelected(item)
		onFilterChange(applyFilter)
		dispatch(setOrdersFilters(applyFilter))
	}

	const myFilterHeader = () => {
		return (
			<div className="my-filter-head">
				<div className="my-filter-head-th" />
				<div className="my-filter-head-name">
					<span>Nome</span>
				</div>
				<div className="my-filter-head-salesperson">
					<span>Representante</span>
				</div>
				<div className="my-filter-head-state">
					<span>Estados</span>
				</div>
				<div className="my-filter-head-status">
					<span>Status</span>
				</div>
				<div className="my-filter-head-category">
					<span>Categoria</span>
				</div>
				<div className="my-filter-head-branch">
					<span>Filial</span>
				</div>
				<div className="my-filter-head-pendency">
					<span>Pendência</span>
				</div>
				<div className="my-filter-head-pendency-code">
					<span>Nome pendência</span>
				</div>
				<div className="my-filter-head-pendency-type">
					<span>Tipo pendência</span>
				</div>
				<div className="my-filter-head-pendency-resolver">
					<span>Resp. solução</span>
				</div>
				<div className="my-filter-head-pendency-resolver-team">
					<span>Setor resp.</span>
				</div>
				<div className="my-filter-head-pendency-date">
					<span>Prazo de pendência</span>
				</div>
				<div className="my-filter-head-options"></div>
				<div className="my-filter-head-th" />
			</div>
		)
	}

	const myFilterBody = () => {
		return (
			<>
				{filters?.map((item, index) => (
					<div className="my-filter-body-list" key={index}>
						<div className="my-filter-body-td" />
						<div className="my-filter-body-options">{optionsContent(item)}</div>
						<div className="my-filter-body-name">
							{!item.id ? (
								<Input
									name="nameFilter"
									onChange={(e) => setNameFilter(e.target.value)}
									id="name-filter"
									placeholder="Nomeie seu filtro"
									variant="light-normal-check"
									onValueInput={async () => {
										await onSubmit({ name: nameFilter })
										requestFilters()
										newFilter(false)
									}}
								/>
							) : (
								<Radio
									name={`${item.name}`}
									type={'radio'}
									variant="blue"
									id={`${item.id}`}
									checked={item.id === filterSelected?.id}
									onChange={async () => {
										await handleSelect(item)
										onClose(false)
									}}
								/>
							)}
						</div>
						<div className="my-filter-body-salesperson">
							<span
								title={`${convertingLabel(
									item.salesPersonCode,
									salespersonList
								)}`}
							>
								{convertingLabel(item.salesPersonCode, salespersonList)}
							</span>
						</div>
						<div className="my-filter-body-state">
							<span title={`${item.federatedState.join(', ')}`}>
								{item.federatedState.join(', ')}
							</span>
						</div>
						<div className="my-filter-body-status">
							<span
								title={`${convertingLabel(item.orderStatus, OrderStatusTypes)}`}
							>
								{convertingLabel(item.orderStatus, OrderStatusTypes)}
							</span>
						</div>
						<div className="my-filter-body-category">
							<span
								title={`${convertingLabel(
									item.orderCategory,
									OrderCategoryTypes
								)}`}
							>
								{convertingLabel(item.orderCategory, OrderCategoryTypes)}
							</span>
						</div>
						<div className="my-filter-body-branch">
							<span
								title={`${convertingLabel(item.orderBranch, OrderBranchTypes)}`}
							>
								{convertingLabel(item.orderBranch, OrderBranchTypes)}
							</span>
						</div>
						<div className="my-filter-body-pendency">
							<span>
								{item.pendencyStatus === '1,2'
									? 'Sim'
									: item.pendencyStatus === '3'
									? 'Não'
									: '-'}
							</span>
						</div>
						<div className="my-filter-body-pendency-code">
							<span
								title={convertingLabel(item.pendencyCode, [
									...availablePendencyList.commercial_pending,
									...availablePendencyList.financial_pending,
									...availablePendencyList.administrative_pending,
									...availablePendencyList.shipment_pending
								])}
							>
								{convertingLabel(item.pendencyCode, [
									...availablePendencyList.commercial_pending,
									...availablePendencyList.financial_pending,
									...availablePendencyList.administrative_pending,
									...availablePendencyList.shipment_pending
								])}
							</span>
						</div>
						<div className="my-filter-body-pendency-type">
							<span
								title={`${convertingLabel(item.pendencyType, PendencyTypes)}`}
							>
								{convertingLabel(item.pendencyType, PendencyTypes)}
							</span>
						</div>
						<div className="my-filter-body-pendency-resolver">
							<span>{item.pendencyIssueResolver}</span>
						</div>
						<div className="my-filter-body-pendency-resolver-team">
							<span
								title={`${convertingLabel(
									item.pendencyIssueResolverTeam,
									ResponsibleSolutionTypes
								)}`}
							>
								{convertingLabel(
									item.pendencyIssueResolverTeam,
									ResponsibleSolutionTypes
								)}
							</span>
						</div>
						<div className="my-filter-body-pendency-date">
							<span
								title={`${convertingLabel(item.pendencyDate, listChangeData)}`}
							>
								{convertingLabel(item.pendencyDate, listChangeData)}
							</span>
						</div>
						<div className="my-filter-body-td" />
					</div>
				))}
			</>
		)
	}

	return (
		<Modal.root>
			<Modal.header
				title="MEUS FILTROS"
				onClose={() => {
					myFiltersCloseModal(false)
					requestFilters()
				}}
				variant="gradient"
			/>
			<Modal.body>
				<Modal.body.main>
					<div className="my-filter-container">
						<div className="my-filter-content">
							<div className="my-filter-table">
								<div className="my-filter-header-content">
									{myFilterHeader()}
								</div>
								<div className="my-filter-body-content">{myFilterBody()}</div>
							</div>
						</div>
						<div className="new-filter">
							<a
								onClick={() => {
									newFilter(true)
									myFiltersCloseModal(false)
								}}
							>
								<img src={IconAdd} />
								<span>Criar Novo Filtro</span>
							</a>
						</div>
					</div>
				</Modal.body.main>
			</Modal.body>
		</Modal.root>
	)
}

export default MyFilters
