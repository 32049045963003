import { ConfirmModal, Input, Select } from '@praticabr/ppsa'
import { Order } from 'module/orderManagement/domain/models/order'
import { ItemsFields } from 'module/orderManagement/domain/usecases/add-order'
import { IAccessories } from 'module/orderManagement/models/IAccessories'
import { AccessoriesTypes } from 'module/orderManagement/utils/constants'
import React, { useEffect, useState } from 'react'
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form'
import { MdDeleteOutline, MdVisibility } from 'react-icons/md'
import { toast } from 'react-toastify'
import ArrowIcon from 'shared/components/SvgIcons/ArrowIcon'
import { store } from 'store'
import { ItensDetailsListAccessories } from '../../ItensDetailsListAccessories'
import './styles.scss'

type OrderItemsContentEditingModeProps = {
	table: string
	order?: Order
	form: UseFormReturn<ItemsFields, any, any>
	fieldArray: UseFieldArrayReturn<ItemsFields, 'items', 'id'>
	isEditing?: boolean
	branch: string | undefined
}

const resolveTablePrice = (item: any, table: any): number =>
	typeof item?.table_price === 'object'
		? item?.table_price?.filter((y: any) => y.table === table)[0]?.value || 0
		: item?.table_price

export const OrderItemsContentEditingWeb = ({
	order,
	form,
	fieldArray,
	table,
	isEditing = false,
	branch = '01'
}: OrderItemsContentEditingModeProps) => {
	const [accessoriesListShowModal, setAccessoriesListShowModal] =
		useState<boolean>(false)
	const { operationTypes, storageList } = store.getState().baseLists
	const [accessoriesItem, setAccessoriesItem] = useState<any>()
	const [itemDeleting, setItemDeleting] = useState<any>()
	const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
		useState<boolean>(false)

	const {
		register,
		setValue,
		getValues,
		getFieldState,
		formState: { errors }
	} = form

	const { fields } = fieldArray

	const handleChangesRemove = (item: any, index: number) => {
		setShowConfirmDeleteModal(true)
		setItemDeleting({ item, index })
	}

	const handleOnConfirmDelete = () => {
		if (itemDeleting) {
			setShowConfirmDeleteModal(false)
			onDeleteItem(itemDeleting.index)
		}
	}

	const handleOnCancelConfirmDelete = () => {
		setShowConfirmDeleteModal(false)
		setItemDeleting(undefined)
	}

	useEffect(() => {
		let tablePrice
		fields.forEach((item, index) => {
			tablePrice = resolveTablePrice(item, table)
			if (order?.price_table && table) {
				if (order?.price_table === table) {
					onChangePriceTotal(
						index,
						getValues(`items.${index}.unit_price`),
						`items.${index}.unit_price`
					)
					onChangeDiscount(index, item, getValues(`items.${index}.unit_price`))
					setValue(
						`items.${index}.unit_price`,
						getValues(`items.${index}.unit_price`)
					)
				} else if (order?.price_table !== table) {
					onChangePriceTotal(index, tablePrice, `items.${index}.unit_price`)
					onChangeDiscount(index, item, tablePrice)
					setValue(`items.${index}.unit_price`, tablePrice)
				}
			}
		})
	}, [table])

	useEffect(() => {
		if (isEditing) {
			branch &&
				branch !== order?.branch &&
				fields.forEach((item, index) => {
					setValue(`items.${index}.storage`, '')
					const element = document.getElementById(
						`storage-${index}`
					) as HTMLInputElement
					element.value = ''
					index === 0 &&
						toast.info(
							'FILIAL ALTERADA! POR FAVOR, ATUALIZE O ARMAZÉM DE CADA ITEM.'
						)
				})
		} else {
			fields.forEach((item, index) => {
				setValue(`items.${index}.storage`, '')
				const element = document.getElementById(
					`storage-${index}`
				) as HTMLInputElement
				element.value = ''
				index === 0 &&
					toast.info(
						'FILIAL ALTERADA! POR FAVOR, ATUALIZE O ARMAZÉM DE CADA ITEM.'
					)
			})
		}
	}, [branch])

	const handleChangeAccessories = (value: string, index: number) => {
		const element = document.querySelector(
			`.accessories-icon-${index}`
		) as HTMLElement
		if (value === '1' && element) {
			element.style.display = 'flex'
		} else if (value === '2' && element) {
			element.style.display = 'none'
		}
	}
	const onChangeAccessories = (data: IAccessories[]) => {
		const currentIndex = fields?.findIndex((item) => item?.id === data[0]?.item)
		if (data?.length > 0) {
			setValue(`items.${currentIndex}.accessories`, data)
			setAccessoriesListShowModal(false)
		}
	}

	const onChangeDiscount = (index: number, item: any, value: number) => {
		const tablePrice = resolveTablePrice(item, table)
		if (value < tablePrice && tablePrice > 0 && item?.table_price != value) {
			const discount = ((tablePrice - value) * 100) / tablePrice
			setValue(`items.${index}.discount`, Number(discount.toFixed(2)))
		} else {
			setValue(`items.${index}.discount`, 0)
		}
	}

	const onChangeDiscountPriceUnit = (
		index: number,
		item: any,
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const tablePrice = resolveTablePrice(item, table)
		if (
			event.target.value &&
			tablePrice &&
			!getValues(`items.${index}.unit_price`)
		) {
			const price_unit =
				tablePrice - tablePrice * (Number(event.target.value) / 100)
			setValue(`items.${index}.unit_price`, price_unit)
		}
	}

	const onChangePriceTotal = (index: number, value: number, option: string) => {
		if (option === `items.${index}.amount`) {
			const price_total = value * getValues(`items.${index}.unit_price`)
			setValue(`items.${index}.net_price_total`, Number(price_total.toFixed(2)))
		}
		if (option === `items.${index}.unit_price`) {
			const price_total = value * getValues(`items.${index}.amount`)
			setValue(`items.${index}.net_price_total`, Number(price_total.toFixed(2)))
		}
		if (option === `items.${index}discount`) {
			const price_total =
				getValues(`items.${index}.unit_price`) *
				getValues(`items.${index}.amount`)
			setValue(`items.${index}.net_price_total`, Number(price_total.toFixed(2)))
		}
	}

	const onDeleteItem = (index: number) => {
		if (isEditing && getValues(`items.${index}.item`) !== '') {
			const { deleted, amount, storage, option, unit_price, ...rest } =
				getValues(`items.${index}`)
			fieldArray.update(index, {
				deleted: true,
				amount: amount || 1,
				storage: storage || '09',
				option: option || '01',
				unit_price: unit_price || 1,
				...rest
			})
		} else {
			fieldArray.fields.splice(index, 1)
			fieldArray.remove(index)
		}
	}

	const accessoriesList = () => (
		<>
			{accessoriesListShowModal && (
				<ItensDetailsListAccessories.Root
					onClose={() => setAccessoriesListShowModal(false)}
				>
					<ItensDetailsListAccessories.EditingMode
						{...{
							onChangeAccessories
						}}
						onClose={() => {
							setAccessoriesListShowModal(false)
						}}
						itemSelect={accessoriesItem}
					/>
				</ItensDetailsListAccessories.Root>
			)}
		</>
	)

	useEffect(() => {
		const elementItem = document.querySelector(
			'.items-information'
		) as HTMLElement
		!order &&
			elementItem &&
			elementItem.scrollIntoView({ behavior: 'smooth', block: 'start' })
	}, [])

	return (
		<div className="items-information-contain">
			<div className="items-table">
				<div className="items-body-container">
					{fields.map((item: any, index: number) => {
						const tablePrice = resolveTablePrice(item, table)
						return (
							!item?.deleted && (
								<div key={item?.id} className="container-item">
									<details
										id={`items-list-details-${index}`}
										className="items-list-details"
									>
										<summary id={item?.id} className={`items-list`}>
											<ArrowIcon
												className="arrow-icon"
												style={{ minWidth: '60px' }}
												id={`arrow-${index}`}
												width={''}
												height={''}
											/>
											<ul className="items-list-order-item items-list-order-product-code">
												<div className="order-product-container">
													<span>{item?.code}</span>
												</div>
											</ul>
											<ul className="items-list-order-item items-list-order-description">
												<span>{item?.description}</span>
											</ul>
											<ul className="items-list-order-item items-list-order-accessories">
												<div
													className="container-accessories"
													style={{ display: 'flex', alignItems: 'center' }}
												>
													<Select
														id={`has_accessories-${index}`}
														options={AccessoriesTypes}
														selectedOption={[
															getValues(`items.${index}.has_accessories`)
														]}
														onSelectOptions={(option) => {
															setValue(
																`items.${index}.has_accessories`,
																option[0]
															)
															handleChangeAccessories(option[0], index)
														}}
														innerRef={register(
															`items.${index}.has_accessories`
														)}
													/>
													<span
														className={`accessories-icon-${index}`}
														style={{
															display:
																item?.has_accessories === '1' ? 'flex' : 'none',
															alignItems: 'center'
														}}
													>
														{
															<MdVisibility
																size={20}
																color="var(--primary-pure)"
																onClick={() => {
																	setAccessoriesListShowModal(true)
																	setAccessoriesItem({
																		...item,
																		accessories:
																			getValues(`items.${index}.accessories`) ||
																			[]
																	})
																}}
															/>
														}
													</span>
												</div>
											</ul>
											<ul className="items-list-order-item items-list-order-amount">
												<Input
													variant="light"
													type="number"
													id={`items.${index}.amount`}
													defaultValue={item?.amount}
													{...register(`items.${index}.amount`, {
														required: true,
														min: 0.01,
														valueAsNumber: true
													})}
													onChange={(event) => {
														onChangePriceTotal(
															index,
															Number(event.target.value),
															`items.${index}.amount`
														)
													}}
													min={0}
													maxLength={9}
													errors={getFieldState(`items.${index}.amount`).error}
												/>
											</ul>
											<ul className="items-list-order-item items-list-order-table-price">
												<Input
													money={true}
													type="number"
													variant="light"
													disabled={true}
													value={tablePrice}
													name={`items.${index}.table_price`}
												/>
											</ul>
											<ul className="items-list-order-item items-list-order-unit-price">
												<Input
													money={true}
													type="number"
													variant="light"
													id={`items.${index}.unit_price`}
													defaultValue={item?.unit_price}
													errors={
														getFieldState(`items.${index}.unit_price`).error
													}
													{...register(`items.${index}.unit_price`, {
														required: true,
														valueAsNumber: true
													})}
													onChange={(e) => {
														onChangeDiscount(
															index,
															item,
															Number(e.target.value)
														)
														onChangePriceTotal(
															index,
															Number(e.target.value),
															`items.${index}.unit_price`
														)
													}}
													onLoadStart={() => {
														setValue(
															`items.${index}.unit_price`,
															Number(tablePrice)
														)
													}}
												/>
											</ul>

											<ul className="items-list-order-item items-list-order-total-net-price">
												<Input
													money={true}
													type="number"
													variant="light"
													disabled={true}
													id={`items.${index}.net_price_total`}
													defaultValue={getValues(
														`items.${index}.net_price_total`
													)}
													{...register(`items.${index}.net_price_total`, {
														valueAsNumber: true
													})}
												/>
											</ul>
											<ul className="items-list-order-item items-list-order-options">
												<MdDeleteOutline
													size={24}
													color="var(--primary-light)"
													onClick={() => handleChangesRemove(item, index)}
												/>
											</ul>
										</summary>
										<div className={`items-list`}>
											<ul className="items-list-order-item items-list-order-serial-number">
												<header>NS</header>
												<Input
													maxLength={30}
													variant="light"
													defaultValue={item?.serial_number}
													id={`items.${index}.serial_number`}
													{...register(`items.${index}.serial_number`)}
												/>
											</ul>
											<ul className="items-list-order-item items-list-order-operation-type">
												<header>Tipo OP</header>
												<Select
													id={`operation-type-${index}`}
													options={operationTypes}
													selectedOption={[getValues(`items.${index}.option`)]}
													onSelectOptions={(option) => {
														setValue(`items.${index}.option`, option[0])
													}}
													innerRef={{
														...register(`items.${index}.option`, {
															required: true
														})
													}}
													errors={getFieldState(`items.${index}.option`).error}
													tooltip="right"
												/>
											</ul>
											<ul className="items-list-order-item items-list-order-storage">
												<header>Armazém</header>
												<Select
													id={`storage-${index}`}
													options={storageList
														.filter(
															(item: any) => item.storage_branch === branch
														)
														.map((item: { value: any; label: string }) => {
															return {
																value: item.value,
																label: `${item.value} - ${item.label}`
															}
														})}
													selectedOption={[getValues(`items.${index}.storage`)]}
													onSelectOptions={(option) => {
														setValue(`items.${index}.storage`, option[0])
													}}
													innerRef={{
														...register(`items.${index}.storage`, {
															required: true
														})
													}}
													errors={getFieldState(`items.${index}.storage`).error}
													tooltip="right"
												/>
											</ul>
											<ul className="items-list-order-item items-list-order-ipi">
												<header>IPI</header>
												<Input
													disabled
													money={true}
													type="number"
													variant="light"
													name={`items.${index}.ipi`}
													defaultValue={order?.items[index]?.taxes?.ipi?.value}
												/>
											</ul>
											<ul className="items-list-order-item items-list-order-difal">
												<header>DIFAL</header>
												<Input
													disabled
													money={true}
													type="number"
													variant="light"
													name={`items.${index}.difal`}
													defaultValue={
														order?.items[index]?.taxes?.difal?.value
													}
												/>
											</ul>
											<ul className="items-list-order-item items-list-order-st">
												<header>ST</header>
												<Input
													disabled
													money={true}
													type="number"
													variant="light"
													name={`items.${index}.st`}
													defaultValue={order?.items[index]?.taxes?.st?.value}
												/>
											</ul>
											<ul className="items-list-order-item items-list-order-suframa">
												<header>Suframa (R$)</header>
												<Input
													type="number"
													money={true}
													variant="light"
													id={`items.${index}.suframa_value`}
													defaultValue={order?.items[index]?.suframa_value}
													{...register(`items.${index}.suframa_value`, {
														valueAsNumber: true
													})}
												/>
											</ul>
										</div>
										<div></div>
										<div className={`items-list`}>
											<ul className="items-list-order-item items-list-invoice-order-item">
												<header>Nota fiscal</header>
												<span>
													{item?.invoice.number && item?.invoice.serie
														? `${item?.invoice.number} - ${item?.invoice.serie}`
														: '-'}
												</span>
											</ul>
											<ul className="items-list-order-item items-list-order-purchase-order-item">
												<header>Item do pedido de compra</header>
												<Input
													variant="light"
													id={`items.${index}.purchase_order_item`}
													defaultValue={getValues(
														`items.${index}.purchase_order_item`
													)}
													{...register(`items.${index}.purchase_order_item`)}
												/>
											</ul>
											<ul className="items-list-order-item items-list-order-purchase-order-number">
												<header>Nº pedido compra</header>
												<Input
													variant="light"
													id={`items.${index}.purchase_order_number`}
													defaultValue={getValues(
														`items.${index}.purchase_order_number`
													)}
													{...register(`items.${index}.purchase_order_number`)}
												/>
											</ul>
											<ul className="items-list-order-item items-list-order-embedded-freight">
												<header>Frete Embutido</header>
												<Input
													money={true}
													type="number"
													variant="light"
													id={`items.${index}.embedded_freight`}
													defaultValue={getValues(
														`items.${index}.embedded_freight`
													)}
													{...register(`items.${index}.embedded_freight`, {
														valueAsNumber: true
													})}
													onChange={(value) => {
														setValue(
															`items.${index}.embedded_freight`,
															Number(value.target.value)
														)
													}}
												/>
											</ul>
											<ul className="items-list-order-item items-list-order-delivery-date">
												<header>Entrega</header>
												<Input
													type="date"
													variant="light"
													defaultValue={item?.delivery_date}
													id={`items.${index}.delivery_date`}
													{...register(`items.${index}.delivery_date`)}
												/>
											</ul>
											<ul className="items-list-order-item items-list-order-delivery-date">
												<header>Dt. Reprogramada</header>
												<Input
													type="date"
													variant="light"
													defaultValue={item?.rescheduled_delivery_date}
													id={`items.${index}.rescheduled_delivery_date`}
													{...register(
														`items.${index}.rescheduled_delivery_date`
													)}
												/>
											</ul>
											<ul className="items-list-order-item items-list-order-installation-value">
												<header>Valor Instalação</header>
												<Input
													money={true}
													type="number"
													variant="light"
													id={`items.${index}.installation_value`}
													defaultValue={getValues(
														`items.${index}.installation_value`
													)}
													{...register(`items.${index}.installation_value`, {
														valueAsNumber: true
													})}
													onChange={(value) => {
														setValue(
															`items.${index}.installation_value`,
															Number(value.target.value)
														)
													}}
												/>
											</ul>
											<ul className="items-list-order-item items-list-order-discount">
												<header>Total desconto</header>
												<Input
													type="number"
													variant="light"
													disabled={true}
													percentage={true}
													defaultValue={item?.discount}
													id={`items.${index}.discount`}
													{...register(`items.${index}.discount`, {
														valueAsNumber: true
													})}
													onChange={(event) => {
														onChangeDiscountPriceUnit(index, item, event)
														onChangePriceTotal(
															index,
															Number(event.target.value),
															`items.${index}.discount`
														)
													}}
													errors={
														getFieldState(`items.${index}.discount`).error
													}
												/>
											</ul>
										</div>
										<div className={`items-list`}>
											<ul className="items-list-order-item items-list-order-promotion">
												<header>C. Promocional 1</header>
												<Input
													variant="light"
													disabled
													defaultValue={item?.promotional_codes?.C6_PROMO1?.trim()}
												/>
											</ul>
											<ul className="items-list-order-item items-list-order-promotion">
												<header>C. Promocional 2</header>
												<Input
													variant="light"
													disabled
													defaultValue={item?.promotional_codes?.C6_PROMO2?.trim()}
												/>
											</ul>
											<ul className="items-list-order-item items-list-order-promotion">
												<header>C. Promocional 3</header>
												<Input
													variant="light"
													disabled
													defaultValue={item?.promotional_codes?.C6_PROMO3?.trim()}
												/>
											</ul>
											<ul className="items-list-order-item items-list-order-promotion">
												<header>C. Promocional 4</header>
												<Input
													variant="light"
													disabled
													defaultValue={item?.promotional_codes?.C6_PROMO4?.trim()}
												/>
											</ul>
											<ul className="items-list-order-item items-list-order-promotion">
												<header>C. Promocional 5</header>
												<Input
													variant="light"
													disabled
													defaultValue={item?.promotional_codes?.C6_PROMO5?.trim()}
												/>
											</ul>
										</div>
									</details>
									{accessoriesListShowModal && accessoriesList()}
									{showConfirmDeleteModal && (
										<ConfirmModal
											title="EXCLUIR ITEM"
											confirmLabel="SIM"
											cancelLabel="NÃO"
											onCancel={handleOnCancelConfirmDelete}
											onConfirm={handleOnConfirmDelete}
											buttonWidth={'68px'}
										>
											<span>
												Deseja excluir o item {itemDeleting?.product_code}?{' '}
											</span>
										</ConfirmModal>
									)}
								</div>
							)
						)
					})}
				</div>
			</div>
		</div>
	)
}
