import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Input, ListType, Modal, Select } from '@praticabr/ppsa'
import Department from 'models/Department'
import { EmployeesContextElement } from 'module/humanResources/context/EmployeesContext'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import SearchIcon from 'shared/components/SvgIcons/SearchIcon'
import { NewEmployeePrpSchema } from '../../schemas/new-employee-schema'
import './styles.scss'

export default function NewEmployeeModal({ onClose }: { onClose: () => void }) {
	const { departments, onCleanData, onGetSra010, onRegisterEmployee, sra010 } =
		useContext(EmployeesContextElement)
	const [departmentsOptions, setDepartmentOptions] = useState<ListType[]>([])

	const form = useForm<NewEmployeePrpSchema>({
		resolver: zodResolver(NewEmployeePrpSchema),
		defaultValues: {
			department: ''
		}
	})

	useEffect(() => {
		if (departments.length) {
			const listDepartments: any = []
			departments.map((department: Department) => {
				listDepartments.push({
					value: department?.code_protheus,
					label: department.description
				})
			})
			setDepartmentOptions(listDepartments)
		}
	}, [departments, sra010])

	useEffect(() => {
		if (sra010) {
			const { raCc, raCelular, raEmail, raNome, raTelefon } = sra010
			raNome && form.setValue('name', raNome?.toLowerCase()?.split(' ')[0])
			raNome?.split(' ')[1] &&
				form.setValue('surname', raNome?.toLowerCase()?.split(' ')[1])
			raEmail && form.setValue('email', raEmail.toLowerCase())
			raCelular && form.setValue('cel', raCelular)
			raCc && form.setValue('department', raCc)
			raTelefon && form.setValue('phone', raTelefon)
		}
	}, [sra010])

	const onSubmit = (data: NewEmployeePrpSchema) => {
		const departmentId = departments.find(
			(d) => d.code_protheus === data.department
		)?.id
		onRegisterEmployee({
			data: { ...data, departmentId },
			callback: handleCloseModal
		})
	}

	const handleGetSra010 = () => {
		if (!form.getValues('register')) {
			return form.setError('register', {
				type: 'required',
				message: 'O campo matrícula é obrigatório!'
			})
		}
		return onGetSra010(form.getValues('register'))
	}

	const handleCloseModal = () => {
		onCleanData()
		form.reset()
		onClose()
	}

	return (
		<Modal.root>
			<Modal.header
				onClose={handleCloseModal}
				title="Detalhes do Usuário"
				variant="gradient"
			/>
			<Modal.body>
				<Modal.body.main>
					<div className="new-employee-modal">
						<div className="new-employee-form">
							<div className="new-employee-form-rows">
								<div className="form-field-info">
									<label>Matrícula</label>
									<Input
										id="register"
										autoComplete="off"
										variant="light-normal"
										placeholder="Matrícula"
										{...form.register('register')}
										onChange={(e) =>
											e.target.value && form.clearErrors('register')
										}
										errors={form.formState.errors.register}
									/>
								</div>
								<div className="form-field-info">
									<div className="search-action">
										<SearchIcon
											height="40"
											width="40"
											onClick={handleGetSra010}
										/>
									</div>
								</div>
							</div>
							<div className="new-employee-form-rows">
								<div className="form-field-info">
									<label>Nome</label>
									<Input
										id="name"
										placeholder="Nome"
										variant="light-normal"
										errors={form.formState.errors.name}
										{...form.register('name')}
									/>
								</div>
								<div className="form-field-info">
									<label>Sobrenome</label>
									<Input
										id="surname"
										variant="light-normal"
										placeholder="Sobrenome"
										errors={form.formState.errors.surname}
										{...form.register('surname')}
									/>
								</div>
							</div>
							<div className="new-employee-form-rows">
								<div className="form-field-info">
									<label>E-mail</label>
									<Input
										id="email"
										placeholder="E-mail"
										variant="light-normal"
										errors={form.formState.errors.email}
										{...form.register('email')}
										onChange={(e) => form.setValue('email', e.target.value)}
									/>
								</div>
								<div className="form-field-info">
									<label>Departamento</label>
									<Select
										id="department"
										variant="light"
										options={departmentsOptions}
										onSelectOptions={(option) =>
											form.setValue('department', option[0])
										}
										errors={form.formState.errors.department}
										innerRef={{ ...form.register('department') }}
										selectedOption={[form.getValues('department')]}
									/>
								</div>
							</div>
							<div className="new-employee-form-rows">
								<div className="form-field-info">
									<label>Telefone</label>
									<Input
										id="cel"
										variant="light-normal"
										placeholder="Telefone"
										errors={form.formState.errors.cel}
										{...form.register('cel')}
									/>
								</div>
								<div className="form-field-info">
									<label>Telefone Alternativo</label>
									<Input
										id="phone"
										variant="light-normal"
										placeholder="Telefone"
										errors={form.formState.errors.phone}
										{...form.register('phone')}
									/>
								</div>
							</div>
						</div>
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<div className="new-employee-modal-actions">
						<Button
							size="lg"
							title="Salvar"
							variant="confirmation-solid-modal"
							onClick={form.handleSubmit(onSubmit)}
						/>
						<Button
							size="lg"
							title="Cancelar"
							onClick={handleCloseModal}
							variant="cancellation"
						/>
					</div>
				</Modal.body.actions>
			</Modal.body>
		</Modal.root>
	)
}
