import { SocketContextElement } from 'module/technicalServices/context/SocketContext/SocketContext'
import React, { useContext, useEffect, useState } from 'react'
import { getLastVersion, updateUserById } from 'services'
import { store } from 'store'
import handleCleanCacheCookies from 'utils/handle-clean-cache-cookies'
import appPackage from '../../../../../package.json'
import { VersionContextElement } from './VersionContextType'

export type VersionType = {
	app: string
	appVersion: string
	createdAt: Date
	data: string
	id: number
}

export default function VersionProvider(props: any) {
	const { eventListener, disconnect } = useContext(SocketContextElement)
	const [version, setVersion] = useState<VersionType | null>(null)
	const [newVersion, setNewVersion] = useState<boolean>(false)
	const [show, setShow] = useState<boolean>(false)
	const user = store.getState().user

	useEffect(() => {
		if (version) {
			if (
				appPackage.version !== version?.appVersion ||
				(user.profile && version?.appVersion !== user.lastVersion)
			) {
				setShow(true)
			}
		}
	}, [version?.appVersion, user.lastVersion])

	useEffect(() => {
		verifyNewVersion()
		socketEvents()
		return () => {
			disconnect()
		}
	}, [])

	const socketEvents = () => {
		const socketTopic = `notify_prp_report/new_version`
		eventListener(socketTopic, (err: boolean) => {
			if (!err) {
				setNewVersion(true)
				setShow(true)
			} else {
				console.error('Houve uma falha no listener de versões')
			}
		})
	}

	const verifyNewVersion = () => {
		getLastVersion()
			.then((res) => {
				const { appUpdate } = res.data
				setVersion(appUpdate)
				if (
					appPackage.version !== appUpdate.appVersion ||
					(user.profile && appUpdate.appVersion !== user.lastVersion)
				) {
					setShow(true)
				}
			})
			.catch((err) => console.error('Fail on request new updates', err))
	}

	const onUpdateClick = async () => {
		if (user?.profile) {
			console.log(' Atualizando version...', {
				profile: user.profile,
				appVersion: version?.appVersion,
				packageVersion: appPackage.version
			})
			try {
				const response = await getLastVersion()
				const { appVersion } = response.data.appUpdate
				await updateUserById({
					userId: user.profile,
					data: { lastVersion: appVersion }
				})
				handleCleanCacheCookies()
			} catch (error) {
				console.error(error)
			}
		}
	}

	const providerValues = {
		version,
		newVersion,
		show,
		onUpdateClick
	}

	return (
		<VersionContextElement.Provider value={providerValues}>
			{props.children}
		</VersionContextElement.Provider>
	)
}
