import {
	Button,
	GenericTable,
	Input,
	LoadingProgress,
	Modal,
	Paginator,
	Select
} from '@praticabr/ppsa'
import { HeaderSection } from 'module/adminSystem/components/HeaderSection'
import { Apps, handleApps } from 'module/adminSystem/utils/constants'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { MdDeleteOutline } from 'react-icons/md'
import { toast } from 'react-toastify'
import {
	deleteAppsVersion,
	getAppsVersions,
	registerAppsVersions
} from 'services'
import './styles.scss'

export interface AppVersionsResponse {
	versions: {
		id: number
		createdAt: string
		appVersion: string
		app: string
		data: string
	}[]
	count: number
}

export const VersionApps = () => {
	const [isLoading, setLoading] = useState<boolean>(false)
	const [showModal, setShowModal] = useState<boolean>(false)
	const [list, setList] = useState<AppVersionsResponse['versions']>([])
	const [params, setParams] = useState<{
		limit: number
		count: number
		currentPage: number
		search: string | null
	}>({
		count: 1,
		currentPage: 1,
		limit: 20,
		search: null
	})

	const {
		clearErrors,
		formState: { errors },
		getValues,
		handleSubmit,
		register,
		reset,
		setValue
	} = useForm()

	useEffect(() => {
		onRequestVersions(params)
	}, [params.currentPage, params.search])

	const onSearch = (search: string) => {
		setParams({ ...params, search, currentPage: 1 })
	}

	const onRequestVersions = ({ currentPage, limit, search }: any) => {
		setLoading(true)
		getAppsVersions({
			limit,
			offset: currentPage - 1,
			search
		})
			.then((response) => {
				const { versions, count } = response.data
				setParams({ ...params, count })
				setList(versions)
			})
			.catch(() => toast.error('Erro ao buscar versões'))
			.finally(() => setLoading(false))
	}

	const onOpenReleaseNote = (e: any) => {
		if (e.data) {
			window.open(e.data, '_blank')
		}
	}

	const onDeleteById = async (id: number) => {
		setLoading(true)
		deleteAppsVersion(id)
			.then(() => {
				onRequestVersions({ ...params, currentPage: 1, search: null })
				toast.success('Versão deletada com sucesso')
			})
			.catch(() => toast.error('Erro ao deletar versão'))
			.finally(() => setLoading(false))
	}

	const onSubmit = async (data: any) => {
		setLoading(true)
		registerAppsVersions(data)
			.then(() => {
				toast.success('Versão registrada com sucesso')
				onCloseModal()
			})
			.catch(() => toast.error('Erro ao registrar versão'))
			.finally(() => setLoading(false))
	}

	const onCloseModal = () => {
		onRequestVersions({ ...params, currentPage: 1, search: null })
		setShowModal(false)
		reset()
	}

	return (
		<>
			<article className="version-list">
				<HeaderSection.root>
					<HeaderSection.search
						onChangeValues={onSearch}
						text="Buscar versão release ou aplicativo"
					/>
					<HeaderSection.button
						text="NOVA VERSÃO"
						onClick={() => setShowModal(true)}
					/>
				</HeaderSection.root>
				<div className="version-table">
					<GenericTable
						onRowClicked={onOpenReleaseNote}
						data={[
							...list.map((version) => ({
								...version,
								app: handleApps(version.app),
								appVersion: version.appVersion,
								createdAt: new Date(version.createdAt).toLocaleDateString(),
								actionTable: (
									<MdDeleteOutline
										size={24}
										color="var(--primary-light)"
										onClick={() => onDeleteById(version.id)}
									/>
								)
							}))
						]}
						columns={[
							{
								key: 'app',
								header: 'Aplicativo',
								width: '20%'
							},
							{
								key: 'appVersion',
								header: 'Versão',
								width: '20%'
							},
							{
								key: 'createdAt',
								header: 'Data',
								width: '25%'
							},
							{
								key: 'data',
								header: 'Release Note',
								width: 'calc(35% - 36px)'
							},
							{
								key: 'actionTable',
								header: '',
								width: '36px'
							}
						]}
					/>
				</div>
				{!!list?.length && (
					<div className="version-list-paginator">
						<Paginator
							totalPages={Math.ceil(params.count / params.limit)}
							currentPage={params.currentPage}
							onPageChange={(e) => setParams({ ...params, currentPage: e })}
						/>
					</div>
				)}
			</article>
			{showModal && (
				<Modal.root>
					<Modal.header
						title="Nova versão"
						onClose={onCloseModal}
						variant="gradient"
					/>
					<Modal.body>
						<Modal.body.main>
							<div className="new-app-version-modal">
								<div className="new-app-version-form">
									<div className="new-follow-up-form-select">
										<header>Aplicação</header>
										<Select
											id="app"
											options={Apps}
											errors={errors.app}
											selectedOption={[getValues('app')]}
											innerRef={{
												...register('app', {
													required: {
														value: true,
														message: 'Selecione a aplicação'
													}
												})
											}}
											onSelectOptions={(option) => {
												setValue('app', option[0])
												clearErrors('app')
											}}
										/>
									</div>
									<div className="new-app-version-form-inputs">
										<div className="new-app-version-form-input">
											<header>Versão</header>
											<Input
												id="version"
												variant="light"
												errors={errors.version}
												{...register('version', { required: true })}
											/>
										</div>
										<div className="new-app-version-form-input">
											<header>Release Note Link</header>
											<Input
												variant="light"
												id="releaseNote"
												errors={errors.releaseNote}
												{...register('releaseNote')}
											/>
										</div>
									</div>
								</div>
							</div>
						</Modal.body.main>
						<Modal.body.actions>
							<div className="new-app-version-actions">
								<Button
									size="lg"
									title="ENVIAR"
									variant="confirmation-solid-modal"
									onClick={handleSubmit(onSubmit)}
								/>
								<Button
									size="lg"
									title="CANCELAR"
									variant="cancellation"
									onClick={onCloseModal}
								/>
							</div>
						</Modal.body.actions>
					</Modal.body>
				</Modal.root>
			)}
			{isLoading && <LoadingProgress />}
		</>
	)
}
