import { Modal } from '@praticabr/ppsa'
import Client from 'models/Client'
import Equipment from 'models/Equipment'
import { Transfer } from 'models/Transfer'
import { TransfersDetailsContextElement } from 'module/technicalServices/context/Transfers/TransfersDetailsContext/TransfersDetailsContext'
import moment from 'moment-timezone'
import React, { useContext, useState } from 'react'
import { TransferOrigin } from 'utils/Constants'
import { normalizeCGC } from 'utils/Pipe'
import TransferEdit from '../../TransferEdit'

interface Props {
	transfer: Transfer
}

function getCurrentClient(transfer: Transfer) {
	return transfer.transferPayloadParsed?.prpInfo?.currentClient
}

function getEquipment(transfer: Transfer) {
	return transfer.transferPayloadParsed?.prpInfo?.equipment
}

const TransferEquipmentContent: React.FC<Props> = ({ transfer }) => {
	const { transferSelected } = useContext(TransfersDetailsContextElement)
	const [isEditing, setIsEditing] = useState<boolean>(false)
	const [equipment, setEquipment] = useState<Equipment>(getEquipment(transfer))
	const [currentClient, setCurrentClient] = useState<Client>(
		getCurrentClient(transfer)
	)

	const onEditClick = () => {
		if (transfer.userId) {
			setIsEditing(true)
		}
	}

	const onClose = () => {
		setIsEditing(false)
	}

	const onCloseEdit = (transferUpdated?: Transfer) => {
		onClose()
		updateContentInfo(transferUpdated || transferSelected)
	}

	const updateContentInfo = (transferUpdate?: Transfer) => {
		if (transferUpdate) {
			setEquipment(getEquipment(transferUpdate))
			setCurrentClient(getCurrentClient(transferUpdate))
		}
	}

	const onUpdateComplete = (transferUpdated?: Transfer) => {
		onCloseEdit(transferUpdated)
	}

	const equipContent = () => {
		return (
			<div className="equip-content">
				<div className="transfer-contact-info">
					<div>
						<span title={equipment.name}>
							<b>Modelo:</b>
							{equipment.name}
						</span>
						<span>
							<b>Número de Série:</b>
							{equipment.serial_number}
						</span>
						<span>
							<b>Data de Venda:</b>
							{moment(equipment.sale_date).format('DD/MM/YYYY')}
						</span>
					</div>
					<div>
						<span title={currentClient.nome_fantasia}>
							<b>Cliente Atual:</b>
							{currentClient.nome_fantasia}
						</span>
						<span>
							<b>CPF/CNPJ do Cliente:</b>
							{normalizeCGC(currentClient.cpfCnpj)}
						</span>
						<span>
							<b>Código de Cliente:</b>
							{currentClient.cod_protheus}
						</span>
					</div>
				</div>
			</div>
		)
	}

	const editEquipmentModalContent = () => {
		const title = 'Alterar Equipamento'
		const show = isEditing
		return (
			show && (
				<Modal.root>
					<Modal.header {...{ title, onClose }} variant="gradient" />
					<Modal.body>
						<Modal.body.main>
							<TransferEdit
								{...{ transfer, type: 'Equipment', onUpdateComplete }}
							/>
						</Modal.body.main>
					</Modal.body>
				</Modal.root>
			)
		)
	}

	const transferOptionsContent = () => {
		const disabledClass = !transferSelected?.userId ? 'disabled' : ''

		return transferSelected?.origin === TransferOrigin.PRP ? (
			<div className={`transfer-options ${disabledClass}`}>
				<span id="joyride-equipment-transferdetail" onClick={onEditClick}>
					Alterar equipamento
				</span>
			</div>
		) : (
			<></>
		)
	}

	return (
		<>
			<section className="transfers-equip-info-content">
				<div className="transfers-info-header">
					<h6>Equipamento</h6>
				</div>
				{equipContent()}
				{transferOptionsContent()}
			</section>
			{editEquipmentModalContent()}
		</>
	)
}

export default TransferEquipmentContent
