import { MoreOptions } from '@praticabr/ppsa'
import Filter from 'models/Filter'
import React, { useState } from 'react'
import FilterOs from '../../FilterOs'

interface FilterListWebProps {
	filters: Filter[]
	handleChangesEdit: (worked: boolean) => void
	handleChangesRemove: (filter: Filter) => void
}

const FilterListWeb = ({
	filters,
	handleChangesEdit,
	handleChangesRemove
}: FilterListWebProps) => {
	const [hasEditingFilter, setHasEditingFilter] = useState<boolean>(false)
	const [filter, setFilter] = useState<Filter>()

	const listMap = (list: string[]) => {
		if (list?.length) {
			return (
				<div>
					{list.map((item, index) =>
						index !== list?.length - 1 ? item + ', ' : item + ' '
					)}
				</div>
			)
		} else {
			return '-'
		}
	}

	const filterListHeaderContain = () => {
		return (
			<div className="filter-view-contain-table-header">
				<div className="filter-view-contain-table-column column-filter-name">
					<span>Filtro</span>
				</div>
				<div className="filter-view-contain-table-column column-filter-states">
					<div>Estados</div>
				</div>
				<div className="filter-view-contain-table-column column-filter-status">
					<div>Status</div>
				</div>
				<div className="filter-view-contain-table-column column-filter-type">
					<div>Tipo de OS</div>
				</div>
				<div className="filter-view-contain-table-column column-filter-cliente-group">
					<div>Grupo de Clientes</div>
				</div>
				<div className="filter-view-contain-table-column column-filter-more-options" />
			</div>
		)
	}

	const handleEditFilter = (filter: Filter) => {
		setFilter(filter)
		setHasEditingFilter(true)
	}

	const filterListBodyContain = () => {
		return filters.map((filter, index) => {
			return (
				<div className="filter-view-contain-table-lines" key={index}>
					<div className="filter-view-contain-table-column column-filter-name">
						<span>{filter.name}</span>
					</div>
					<div className="filter-view-contain-table-column column-filter-states">
						{listMap(filter.stateList)}
					</div>
					<div className="filter-view-contain-table-column column-filter-status">
						{listMap(filter.statusList)}
					</div>
					<div className="filter-view-contain-table-column column-filter-type">
						{listMap(filter.typeOsList)}
					</div>
					<div className="filter-view-contain-table-column column-filter-cliente-group">
						{listMap(filter.accountTypeList)}
					</div>
					<div className="filter-view-contain-table-column column-filter-more-options">
						<MoreOptions
							id={`${filter.id}`}
							options={[
								{
									label: 'Editar',
									onClick: () => {
										handleEditFilter(filter)
									}
								},
								{
									label: 'Excluir',
									onClick: () => handleChangesRemove(filter)
								}
							]}
						/>
					</div>
				</div>
			)
		})
	}

	return (
		<div className="filter-view-contain-table">
			{filterListHeaderContain()}
			{filterListBodyContain()}
			{hasEditingFilter && (
				<FilterOs
					handleChanges={handleChangesEdit}
					handleCancel={() => setHasEditingFilter(false)}
					filter={filter}
				/>
			)}
		</div>
	)
}

export default FilterListWeb
